import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateSystemSettings } from "../../redux/actions/system";
import { setMessage } from "../../redux/actions/auth";

import Tabs from "../../components/Tabs/Tabs";
import FileUpload from "../../components/FileUpload/FileUpload";
import Hint from "../../components/Hint/Hint";

const tabs = [
  {id: '1', name: 'Загальні  налаштування'},
  {id: '2', name: 'Кімнати'},
  {id: '3', name: 'Репутація'},
]

const SystemSettingsPage = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [form, setForm] = useState({});
  const { t } = useTranslation();
  const system = useSelector(state => state.system.system);
  const hint = useSelector(state => state.hints);
  
  const dispatch = useDispatch();

  const onChangeTab = (id) => {
    setActiveTab(id);
  };

  const changeHandler = (e) => {
    const { id, value } = e.target;
    const updatedForm = {};

    if (id === 'description' && value.length > 100) {
      return
    } else {
      updatedForm[id] = value;
    }

    setForm({...form, ...updatedForm});
  };

  const validateIsEmpty = () => {
    let valid = true;

    if (activeTab === '1') {
      return Object.keys(system.SettingsSystem).every( key => {
        const value = form[key];

        return value !== undefined && value !== null && value !== '';
      })
    }
    if (activeTab === '2') {
      return Object.keys(system.SettingsWallet).every( key => {
        const value = form[key];

        return value !== undefined && value !== null && value !== '';
      })
    }
    if (activeTab === '3') {
      return Object.keys(system.SettingsPoints).every( key => {
        const value = form[key];

        return value !== undefined && value !== null && value !== '';
      })
    }
  }

  const onSave = () => {
    if (!validateIsEmpty()) {
      dispatch(setMessage({
        message: 'Заповніть всі поля',
        messageType: 'error',
      }))

      return
    };

    const data = {};

    if (activeTab === '1') {
      data.name = form.name;
      data.description = form.description;
      data.email = form.email;
      data.favicon = form.favicon;
      data.metaImage = form.metaImage;

      updateData(data, 'settings-system/')
    }

    if (activeTab === '2') {
      data.commissionAmount = form.commissionAmount;
      data.lowerLimitToken = form.lowerLimitToken;
      data.minimumCost = form.minimumCost;
      data.countdownSystemReadiness = form.countdownSystemReadiness;
      data.soundReadiness = form.soundReadiness;
      data.soundStart = form.soundStart;

      updateData(data, 'settings-wallet/')
    }

    if (activeTab === '3') {
      data.exitsRoomsRow = form.exitsRoomsRow;
      data.exitsRoomsDay = form.exitsRoomsDay;
      data.readyNotPressingButton = form.readyNotPressingButton;
      data.dayNotPressingButton = form.dayNotPressingButton;
      data.gamesWithoutPenalties = form.gamesWithoutPenalties;

      updateData(data, 'settings-points/');
    }
  };

  const updateData = (data, url) => {
    dispatch(updateSystemSettings(data, url)).then( res => {
    }).catch( e => {
      console.log(e);
    })
  }

  const onReset = () => {
    setForm({
      ...system.SettingsSystem,
      ...system.SettingsPoints,
      ...system.SettingsWallet,
    })
  };

  useEffect(() => {
    if (system) {
      setForm({
        ...system.SettingsSystem,
        ...system.SettingsPoints,
        ...system.SettingsWallet,
      });
    }
  }, [system])

  return (
    <>
      <div className="profileSection" style={{marginBottom:'0px'}}>
        <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
          <h2>Налаштування системи
            <div className="infoHelp"> <Hint align='left' item={hint[39]}/></div>
          </h2>
        </div>

        <div className="systemSettings">
          <div className="">
            <Tabs tabs={tabs} onChangeTab={onChangeTab} activeTab={activeTab}/>
          </div>

          <div className="flexCol">
            {activeTab === "1" && (
              <div className="prflexColumn" style={{ gap: '70px' }}>
                <div className="prFlexRow systemSettingsInputs" style={{ gap: '20px', width: '100%' }}>
                  <div style={{ width: '100%', alignSelf: "flex-start" }}>
                    <div className="prInput">
                      <label htmlFor="name" className="prInputLabel">Назва системи</label>
                      <input
                        id="name"
                        type="text"
                        className="prInputInput"
                        value={form.name || ''}
                        onChange={changeHandler}
                        placeholder="Назва системи"
                      />
                    </div>
                    <div className="prInput">
                      <label htmlFor="email" className="prInputLabel">Пошта для повідомлень</label>
                      <input
                        id="email"
                        type="text"
                        className="prInputInput"
                        value={form.email || ''}
                        onChange={changeHandler}
                        placeholder="Пошта для повідомлень"
                      />
                    </div>
                    <div className="prInput">
                      <label htmlFor="description" className="prInputLabel">Опис системи</label>
                      <input
                        id="description"
                        type="text"
                        className="prInputInput"
                        value={form.description || ''}
                        onChange={changeHandler}
                        placeholder="Опис системи"
                      />
                      <span
                        style={{
                          marginTop: '4px',
                          fontSize: '14px',
                          color: 'var(--Text)', 
                          alignSelf: 'flex-end',
                        }}
                      >
                        {form.description && form.description.length}/100
                      </span>
                    </div>
                  </div>
                  
                  <div style={{ width: '100%', alignSelf: "flex-start" }}>
                    <div className="prInput">
                      <label htmlFor="favicon" className={"prInputLabel"}>
                        Favicon
                      </label>
                      <FileUpload id={'favicon'} imageUrl={form.favicon || ''} form={form} setForm={setForm} url='uploads/favicon'></FileUpload>
                    </div>
                    <div className="prInput">
                      <label htmlFor="metaImage" className={"prInputLabel"}>
                        Метазображення
                      </label>
                      <FileUpload id={'metaImage'} imageUrl={form.metaImage || ''} form={form} setForm={setForm} url='uploads/meta-img'></FileUpload>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "2" && (
              <div className="prflexColumn">
                <div className="prInput">
                  <label htmlFor="commissionAmount" className="prInputLabel">{t('Розмір відсотка, який отримує Арбітр в Кімнаті')}</label>
                  <input
                    id="commissionAmount"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.commissionAmount || ''}
                  />
                </div>
                <div className="prInput">
                  <label htmlFor="lowerLimitToken" className="prInputLabel">{t('Нижня межа на виведення Токенів')}</label>
                  <input
                    id="lowerLimitToken"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.lowerLimitToken || ''}
                  />
                </div>
                <div className="prInput">
                  <label htmlFor="minimumCost" className="prInputLabel">{t('Мінімальна вартість участі в кімнаті')}</label>
                  <input
                    id="minimumCost"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.minimumCost || ''}
                  />
                </div>
                <div className="prInput">
                  <label htmlFor="countdownSystemReadiness" className="prInputLabel">{t('Строк зворотного відліку для системи готовності в секундах')}</label>
                  <input
                    id="countdownSystemReadiness"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.countdownSystemReadiness || ''}
                  />
                </div>
                <div className="prInput">
                  <label htmlFor="soundReadiness" className="prInputLabel">{t('Час до сигналу нагадування про готовність')}</label>
                  <input
                    id="soundReadiness"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.soundReadiness || ''}
                  />
                </div>
                <div className="prInput">
                  <label htmlFor="soundStart" className="prInputLabel">{t('Час до сигналу нагадування про початок гри')}</label>
                  <input
                    id="soundStart"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.soundStart || ''}
                  />
                </div>
              </div>
            )}

            {activeTab === "3" && (
              <div className="prflexColumn">
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                  <input
                    id="exitsRoomsRow"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.exitsRoomsRow || ''}
                  />
                  <span> виходи з Кімнати поспіль - 1 бал</span>
                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                  <input
                    id="exitsRoomsDay"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.exitsRoomsDay || ''}
                  />
                  <span> виходи з Кімнати впродовж доби - 2 бали</span>
                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                  <input
                    id="readyNotPressingButton"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.readyNotPressingButton || ''}
                  />
                  <span> ненатискання кнопки готовності поспіль - 1 бал</span>
                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                  <input
                    id="dayNotPressingButton"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.dayNotPressingButton || ''}
                  />
                  <span> ненатискань кнопки готовності впродовж доби - 2 бали</span>
                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                  <input
                    id="gamesWithoutPenalties"
                    className="prInputInput"
                    type="number"
                    min={0}
                    onChange={changeHandler}
                    value={form.gamesWithoutPenalties || ''}
                  />
                  <span> ігор без штрафних санкцій +1 бал</span>
                </div>
              </div>
            )}

            <div className="userEditButtons" style={{ alignSelf: 'center' }}>
                <button className="prButton prButtonSecond" onClick={onReset}>Відхилити</button>
                <button
                  className="prButton prButtonMain"
                  onClick={onSave}
                >
                  Зберегти
                </button>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default SystemSettingsPage;