import {   
  GETBLACKLIST_SUCCESS,
  GETBLACKLIST_FAIL,
  ADDBLACKLISTITEM_SUCCESS,
  ADDBLACKLISTITEM_FAIL,
  DELETEBLACKLISTITEM_SUCCESS,
  DELETEBLACKLISTITEM_FAIL,
 } from "../actions/types";

const initialState = {}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETBLACKLIST_FAIL:
      return [...state];
    case GETBLACKLIST_SUCCESS:
      return [...payload.all];
    case ADDBLACKLISTITEM_SUCCESS:
      return [...state];
    case ADDBLACKLISTITEM_FAIL:
      return [...state];
    default:
      return state;
  }
}