import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';


const getSystemSettings = () => {
  return axios
  .get(constants.URL + "settings/", { headers: authHeader()})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return Promise.reject(e.response.data);
  });
}

const updateSystemSettings = (body, url) => {
  return axios
    .put(constants.URL + "settings/" + url, body, { headers: authHeader() })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e.response.data);
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSystemSettings,
  updateSystemSettings,
}