import {
    ERROR,
    ERROR_FREE,
    SET_MESSAGE
  } from "../actions/types";
  
  const initialState = {};
  
  export default function (state = initialState, action) {
    const { type, message, messageType } = action;
  
    switch (type) {
      case ERROR:
        return {
          ...state,
          type: 'error',
          message:message
        };
      case SET_MESSAGE:
        return {
          ...state,
          type: messageType,
          message: message,
        };
      case ERROR_FREE:
        return {
          ...state,
          message:''
        };
      default:
        return state;
    }
  }
  