import React, { useState } from "react";
import { useSelector } from "react-redux";
import Hint from "../../components/Hint/Hint";

import { Icon } from "../../components/Icon";
import Pagination from "../../components/Pagination/Pagination";

const mockData = [
  '174.29.517.1',
  '174.29.517.2',
  '174.29.517.3',
  '174.29.517.4',
  '174.29.517.5',
  '174.29.517.6',
  '174.29.517.7',
  '174.29.517.8',
  '174.29.517.9',
  '174.29.517.10',
  '174.29.517.11',
  '174.29.517.12',
]

const BlacklistPage = () => {
  const [edit, setEdit] = useState('');
  const [add, setAdd] = useState(false);

  const hint = useSelector(state=>state.hints)

  const changeHandler = () => {};

  const addClick = () => {};

  const editClick = (e) => {
    setEdit(e.currentTarget.id);
  }

  const deleteClick = () => {}

  const saveClick = () => {}

  return (
    <div className="profileSection gamesPage" style={{marginBottom:'0px'}}>
      <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
          <h2 >Чорний список
            <div className="infoHelp"> <Hint align='left' item={hint[36]}/></div>
          </h2>
          <button
            className="prButton prButtonMain prFlexRow"
            style={{gap:'8px', fill:"white"}}
            onClick={() => setAdd(prev => !prev)}
        >
          <Icon type="plus"/>
          Додати IP
        </button>
      </div>
      {add && (
        <div className="directoryElememt prFlexRow">
            <div className="directoryElementText">
                <input className="prInputInput" id='ip' onChange={changeHandler} placeholder="Назва елементу" type="text" />
            </div>
            <div className="prFlexRow directoryElementButton">
                <div className="prIconButton"  onClick={addClick}>
                    <Icon type='accept'></Icon>
                </div>
                <div className="prIconButton" onClick={()=>setAdd(prev => !prev)} >
                    <Icon type='close'></Icon>
                </div>
            </div>
        </div>
      )}

      {mockData.length && mockData.map(ip => (
        <div key={ip} className="directoryElememt prFlexRow">
          {edit === ip ?
            <>
              <div className="directoryElementText">
                  <input className="prInputInput" value={ip} id={ip} onChange={changeHandler} placeholder="Назва елементу" type="text" />
              </div>
              <div className="prFlexRow directoryElementButton">
                  <div className="prIconButton" id={ip} onClick={saveClick}>
                      <Icon type='accept'></Icon>
                  </div>
                  <div className="prIconButton" onClick={()=>setEdit('')} >
                      <Icon type='close'></Icon>
                  </div>
              </div>
            </>: 
            <>
              <div className="directoryElementText">
                {ip}
              </div>
              <div className="prFlexRow directoryElementButton">
                <div className="prIconButton" id={ip} onClick={editClick}>
                    <Icon type='edit'></Icon>
                </div>
                <div className="prIconButton" id={ip} onClick={deleteClick}>
                    <Icon type='close'></Icon>
                </div>
              </div>
            </>}
        </div>
      ))}
      
      {/* {game.total>game.limit && 
        <Pagination
          className="pagination-bar"
          currentPage={pageCur}
          totalCount={game.total}
          pageSize={game.limit}
          onPageChange={(page) => {
              setPageCur(page);
              initFunc(page);
          }}
        />
      } */}
    </div>  
  )
}

export default BlacklistPage;