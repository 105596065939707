import React from "react";
import { useSelector } from "react-redux";
import Hint from "../Hint/Hint";
import { Icon } from "../Icon";


const TeamResult =(props)=>{
    const hint =useSelector(state=>state.hints)


return(
    <>
        <div className="headerResult">{props.isArbitr?'Рішення арбітра':'Запропонуйте результат'}
                                <div className="infoHelp">
                                    <Hint align='center' item={props.isArbitr?hint[96]:hint[95]}/>
                                </div>
        </div>
        <div className="dFlex resultTeam">
            <div className="triangular"><Icon type="triangular" /></div>
            <div className="resultTeamName">Команда 1</div>
            <div className="dFlex resultBlockResult">
                <div
                    onClick={() => props.handleIconClick('teamA', 'win')}
                    className={props.activeTeamA === 'win'?'active resultIcon green':'resultIcon' }
                >
                    <Icon type="win" />
                </div>
                <div
                    onClick={() => props.handleIconClick('teamA', 'draft')}
                    className={props.activeTeamA === 'draft'?'active resultIcon orange':'resultIcon' }
                >
                    <Icon type="draft" />
                </div>
                <div
                    onClick={() => props.handleIconClick('teamA', 'lose')}
                    className={props.activeTeamA === 'lose'?'active resultIcon red':'resultIcon'}
                >
                    <Icon type="loose" />
                </div>
            </div>
        </div>
        <div className="teamSeparator"></div>
        <div className="dFlex resultTeam">
            <div className="square"><Icon type="square" /></div>
            <div className="resultTeamName">Команда 2</div>
            <div className="dFlex resultBlockResult">
                <div
                    onClick={() => props.handleIconClick('teamB', 'win')}
                    className={props.activeTeamB === 'win'?'active resultIcon green':'resultIcon'}
                >
                    <Icon type="win" />
                </div>
                <div
                    onClick={() => props.handleIconClick('teamB', 'draft')}
                    className={props.activeTeamB === 'draft'?'active resultIcon orange':'resultIcon'}
                >
                    <Icon type="draft" />
                </div>
                <div
                    onClick={() => props.handleIconClick('teamB', 'lose')}
                    className={props.activeTeamB === 'lose'?'active resultIcon red':'resultIcon'}
                >
                    <Icon type="loose" />
                </div>
            </div>
        </div>
        </>
)

}

export default TeamResult;