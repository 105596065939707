import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getRoomsList } from "../../redux/actions/room";
import getDate from "../../helpers/getDate";
import { socket } from "../../socket";

import DatePicker from "../../components/DatePicker/DatePicker";
import { Icon } from "../../components/Icon";
import TimerPR from "../../components/TimerPR/TimerPR";
import Filter from "../../components/Filter/Filter";
import Hint from "../../components/Hint/Hint";

const sortOptions = [
    { id: "price", value: "Вартість" },
];

const AllRoomsPage=()=>{
    const [list, setList] = useState({});
    const [pageCur, setPageCur] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState('asc')
    const [filterApplying, setFilterApplying] = useState(false);
    const [sortValue,setSortValue]=useState('price');
    const stateList = useSelector((state) => state.list);
    const hint = useSelector((state) => state.hints);
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initFunc=(page=1)=>{
        let data ={};
        data.limit=20;
        data.offset = (page - 1) * data.limit;

        const processedFilter = {...filter};

        Object.keys(processedFilter).forEach((key) => {
            const value = processedFilter[key];
            if (Array.isArray(value)) {
                processedFilter[key] = value.map((item) => item.id);
            }
        });

        dispatch(getRoomsList({...data, ...processedFilter})).then(
            (res) => {
                setList(res)
            },
            (error) => {
                console.error("Error in initFunc:", error);
            }
        )
    }

    const changeHandler = (e) => {
        const { id, value } = e.target || e;
        let updatedFilter = { ...filter };

        console.log('value in parent', id, value);

        if (e.target && e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else if (Array.isArray(value)) {
            if (value.length === 0) {
                delete updatedFilter[id];
            } else {
                updatedFilter[id] = value;
            }
        }
        else if (id === "dateFrom" || id === "dateTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        } 
        else if (!value || value.length < 1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }

        setFilter(updatedFilter);
    };

    const getRooms = (response) => {
        setList(response);
    }

    const resetFilter = () => {
        console.log('resetting...');
        setFilter({});
        setFilterApplying(true);
    }

    const applyFilters = () => {
        setFilterApplying(true);
    }

    useEffect(() => {
        initFunc();  
    }, []);

    useEffect(() => {
        if (filterApplying) {
            console.log('filter', filter);
            initFunc(1, filter);
            setFilterApplying(false);
        }
    }, [filterApplying, filter]);
        

    socket.on('roomsList', getRooms);

    return(
        <div className="profileSection">
            <div className="prFlexRow profileSectionHeader">
                <h2>
                    Всі кімнати
                    <div className="infoHelp"> <Hint align='left' item={hint[34]}/></div>
                </h2>
                <div className="prFlexRow" style={{gap:'25px'}}>
                    <button className="prButton prButtonMainP prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                        <Icon type="filter" /> {t("filter_show")}
                    </button>
                </div>
            </div>
            <div className="filterBlock">
                <div className="prFlexRow">
                    <div className="prFlexRow">
                        <div className="prFlexRow filterBlockFilter">
                            <div className="filterBlockSortText">{t("filter_period")}:</div>
                            <DatePicker id="dateFrom" value={(filter.dateFrom?.length>1 && getDate(filter.dateFrom,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} icon={true} />
                            <div>-</div>
                            <DatePicker id="dateTo" value={(filter.dateTo?.length>1 && getDate(filter.dateTo,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} icon={true} />
                        </div>
                        <div className="filterBlockSort prFlexRow">
                            <div className="filterBlockSortText">{t("filter_sort")}:</div>
                            <div className="filterBlockSortValue prFlexRow">
                                <div className={sort=='asc'?'norm':'pervert'}><Icon type="filterArrow" /></div>
                                <div>{sortOptions[0].value}</div>
                                <Icon type="selectArrow" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="roomList">
                {list?.length>0 &&
                    list?.map(elem=>{
                        return(<>
                            <div className="roomItem prFlexRow actualRoomsBlock allRooms">
                                <div className="roomItemTabletBlockHeader prFlexRow">
                                    <div className="roomItemLink" style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+elem.id)}>{elem.id}</div>
                                    <div className="prFlexRow">
                                        <div className="roomItemBlockValue"><Icon type='time'></Icon>   {getDate(elem.createdAt.replace('T',' '),'hh:mm',":")}</div>
                                        <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {getDate(elem.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                                        <div className="roomItemBlockValue">{stateList[elem.status]}</div>
                                    </div>
                                </div>
                                <div className="roomItemMobileBlockHeader">
                                    <div>
                                        <div className="roomItemBlockHeader">ID кімнати</div>
                                        <div className="roomItemLink" style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+elem.id)}>{elem.id}</div>
                                    </div>
                                    <div className="roomItemMoney">
                                        <div className="roomItemMoneyValue prFlexRow">{elem.price} <Icon type='fTocken'></Icon></div>
                                    </div>
                                </div>
                                <div className="roomItemLink" style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+elem.id)}>{elem.id}</div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('game')}</div>
                                    <div className="roomItemBlockValue">{elem.game?.name}</div>
                                </div>
                                <div className="roomItemMobileTimeBlock">
                                    <div className="roomItemIcon">
                                        <Icon type='time'></Icon>
                                    </div>
                                    <div className="roomItemTime">
                                        {<TimerPR initialSeconds={elem.termExistence}></TimerPR>}
                                    </div>
                                </div>
                                <div className="roomItemTime prFlexRow" style={{ marginRight: '10px' }}>
                                    <div className="roomItemIcon">
                                        <Icon type='time'></Icon>
                                    </div>
                                    <TimerPR initialSeconds={elem.termExistence} />
                                </div>

                                <div className="roomItemMoney">
                                    <div className="roomItemBlockHeader">{t('input_money')}</div>
                                    <div className="roomItemMoneyValue prFlexRow">{elem.price} <Icon type='fTocken'></Icon></div>
                                </div>
                                <div className="roomItemMobileBlock">
                                    <div className="roomItemBlockHeader">{t('type')}</div>
                                    <div className="roomItemBlockValue">{elem.isPrivate?'Приватна':'Публічна'}</div>
                                </div>
                                <div className="roomItemIcon roomItemBlock dFlex platformIcon" style={{gap:'10px'}}>
                                    <div className="roomItemBlockHeader">Платформа</div>
                                    {elem.platform.map(elem=>{
                                        return(<> 
                                                    {elem=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                                        <Icon type='pc'></Icon>
                                                    }
                                                    {elem=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                                    <Icon type='ps'></Icon>
                                                    }
                                                    {elem=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                                    <Icon type='xbox'></Icon>
                                                    }
                                        </>)
                                    })}
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('format')}</div>
                                    <div className="roomItemBlockValue">{elem.format+' x '+elem.format}</div>
                                </div>
                                <div className="roomItemTabletTimeBlock">
                                    <div className="roomItemIcon">
                                        <Icon type='time'></Icon>
                                    </div>
                                    <div className="roomItemTime">
                                        {<TimerPR initialSeconds={elem.termExistence}></TimerPR>}
                                    </div>
                                </div>
                                <div className="roomItemMoney roomItemTabletMoney">
                                    <div className="roomItemBlockHeader">{t('input_money')}</div>
                                    <div className="roomItemMoneyValue prFlexRow">{elem.price} <Icon type='fTocken'></Icon></div>
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('status')}</div>
                                    <div className="roomItemBlockValue">{stateList[elem.status]}</div>
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('time_created')}</div>
                                    <div className="prFlexRow"> 
                                        <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(elem.updatedAt.replace('T',' '),'hh:mm',':')}</div>
                                        <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(elem.updatedAt.replace('T',' '),'dd/mm/yyyy','.')}</div>
                                    </div>
                                </div>
                            </div>  
                        </>)
                    })
                }              
            </div>
            {isOpen && <Filter type='rooms' resetFilter={resetFilter} accept={applyFilters} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
        </div>
    )

}

export default AllRoomsPage;