import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FileUpload from "../../../components/FileUpload/FileUpload";
import { Icon } from "../../../components/Icon";
import getDate from "../../../helpers/getDate";
import { createMessageSupport, getSupportDetail } from "../../../redux/actions/support";
import checkRole from "../../../helpers/checkRole";


const SupportItemPage =()=>{
    const { t } = useTranslation();
    const [ready, setReady] = useState(false);
    const currentId = useParams().id;
    const navigate = useNavigate();
    const dispatch= useDispatch();
    const support = useSelector((state) => state.support.currentsupport?.message);
    const list = useSelector(state=>state.list)
    const [form, setForm] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const user = useSelector((state) => state.currentUser.user);


    const initFunc =(page)=>{
        dispatch(getSupportDetail({id:currentId})).then(res=>{
            setReady(true)
        })
    }
    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
    };

    const clearClick=()=>{
        setIsOpen(false);
        setForm({});
    }
    const sendClick=()=>{
        let data={...form};
        data.id=currentId;
        if(user?.lastName?.length>0 && user?.firstName?.length>0){
            data.name=user.lastName+' '+user.firstName;
        }else{
            data.name=user.nickname;
        }
        dispatch(createMessageSupport(data)).then(res=>{
            setIsOpen(false);
            setForm({});
            initFunc();
        })
    }
    useEffect(() => {
        if(!ready){
            initFunc();
        }
    }, [ready]);

    const onNicknameClick = (id) => {
        if (!checkRole(user, 'admin')) return;

        navigate(`/profile/settings/${id}`)
    };

    return(<>
         {ready &&<div className="profileSection supportItemDetails" style={{marginBottom:'0px'}}>
                <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
                    <h2 >
                        Запит #{support.id} - {support.message.substring(0, 30)}
                    </h2>
                </div>
                <div className="dFlex" style={{gap:'21px'}}>
                    <div className="supportAlign">
                        <div className="createSupportLeftTabletBlock">
                            <div className="createSupport createSupportLeft">
                                <div className="createSupportHeaderSup">Інформація про запит</div>
                                <div>
                                    <div className="createSupportTextHeader">Статус</div>
                                    <div className="createSupportText">{list[support.status]}</div>
                                </div>
                                <div>
                                    <div className="createSupportTextHeader">Користувач</div>
                                    <div className="createSupportText">{support.name}</div>
                                </div>
                                <div>
                                    <div className="createSupportTextHeader">Відділ</div>
                                    <div className="createSupportText">{list[support.department]}</div>
                                </div>

                                <div>
                                    <div className="createSupportTextHeader">Час створення</div>
                                    <div className="dFlex createSupportText"> 
                                        <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(support.createdAt?.replace('T',' '),'hh:mm',':')}</div>
                                        <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(support.createdAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="createSupportTextHeader">Оновлення</div>
                                    <div className="dFlex createSupportText"> 
                                        <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(support.createdAt?.replace('T',' '),'hh:mm',':')}</div>
                                        <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(support.updatedAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                                    </div>
                                </div>

                                <div>
                                    <div className="createSupportTextHeader">Приоритет</div>
                                    <div className="createSupportText">{list[support.priority]}</div>
                                </div>
                                
                            </div>
                            <div className="createSupport createSupportLeft">
                                <div className="createSupportHeaderSup">Вкладення</div> 
                                <div className="downloadItems">
                                    {support?.img.length>0&& support?.img?.map(el=>
                                            <div className="dFlex supportFileDownloadItem" style={{gap:'10px'}} onClick={()=>window.open(el.url)}>
                                            <div><Icon type='download'></Icon></div>
                                            <div className="createSupportTextHeader">{el?.name?.substring(0, 30)}</div>
                                        </div>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="createSupport createSupportLeft">
                            <div className="createSupportHeaderSup">Інформація про запит</div>
                            <div>
                                <div className="createSupportTextHeader">Статус</div>
                                <div className="createSupportText">{list[support.status]}</div>
                            </div>
                            <div>
                                <div className="createSupportTextHeader">Користувач</div>
                                <div className="createSupportText">{support.name}</div>
                            </div>
                            <div>
                                <div className="createSupportTextHeader">Відділ</div>
                                <div className="createSupportText">{list[support.department]}</div>
                            </div>

                            <div>
                                <div className="createSupportTextHeader">Час створення</div>
                                <div className="dFlex createSupportText"> 
                                    <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(support.createdAt?.replace('T',' '),'hh:mm',':')}</div>
                                    <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(support.createdAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                                </div>
                            </div>
                            <div>
                                <div className="createSupportTextHeader">Оновлення</div>
                                <div className="dFlex createSupportText"> 
                                    <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(support.createdAt?.replace('T',' '),'hh:mm',':')}</div>
                                    <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(support.updatedAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                                </div>
                            </div>

                            <div>
                                <div className="createSupportTextHeader">Приоритет</div>
                                <div className="createSupportText">{list[support.priority]}</div>
                            </div>
                            
                        </div>
                        <div className="dFlex buttonSize" style={{gap:'20px', marginTop:'50px', marginBottom:'20px'}}>
                            <button className="prButton prButtonSecond"   onClick={()=>navigate('/supportList')}>Закрити</button>
                            <button className="prButton prButtonMain"   onClick={()=>setIsOpen(true)}>Відповісти</button>
                        </div>
                        <button className="prButton prButtonMain"   onClick={()=>navigate('/supportList')}>До списку запитів</button>                        

                        <div className="createSupport createSupportLeft" style={{marginTop:'50px'}}>
                            <div className="createSupportHeaderSup">Вкладення</div> 
                            <div className="downloadItems">
                                {support?.img.length>0&&support?.img?.map(el=>
                                        <div className="dFlex supportFileDownloadItem" style={{gap:'10px'}} onClick={()=>window.open(el.url)}>
                                        <div><Icon type='download'></Icon></div>
                                        <div className="createSupportTextHeader">{el?.name?.substring(0, 30)}</div>
                                    </div>
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className="supportMessageList">
                        <div className="supportMessage">
                            <div className="supportMessageHeader create dFlex">  
                                <div className="supportMessageHeaderLeft dFlex">
                                    <div>
                                        <Icon type='edit'></Icon> 
                                    </div>
                                    <div>
                                        <div className="supportMessageHeaderLeftHead">
                                            Відповідь.
                                        </div>
                                    </div>
                                </div>
                                <div className={`supportMessageHeaderRight openRec ${isOpen?'open':''} dFlex`} onClick={()=>setIsOpen(!isOpen)}>
                                    <Icon type='rightarrow'></Icon>     
                                 </div>
                            </div>

                            {isOpen&&<div className="supportMessageBody">
                                <div className="prInput">
                                    <label htmlFor="message" className={"prInputLabel"}>{t("Напишіть свою відповідь")}</label>
                                    <textarea onChange={changeHandler} id="message" value={form.message} placeholder={t("enter_value")} cols="30" rows="5"></textarea>
                                </div>
                                <div style={{marginBottom:'30px'}}>
                                    Ви можете завантажити до 3 файлів у одному повідомленні. Якщо хочете передати нам більше файлів, можете використати посилання на Dropbox чи Google Drive
                                </div>
                                <FileUpload id={'img'} form={form} setForm={setForm} maxFiles={3}></FileUpload>
                                <div className="dFlex buttonSize" style={{gap:'20px', marginTop:'50px', marginBottom:'20px'}}>
                                    <button className="prButton prButtonSecond"   onClick={clearClick}>Відмінити</button>
                                    <button className="prButton prButtonMain"   onClick={sendClick}>Надіслати</button>
                                </div>
                            </div>}
                           
                        </div>

                       {support?.supportMessage?.length>0 && support?.supportMessage.map(el=>{
                           return(
                            <>
                                {user.id!==el.createdBy?<div className="supportMessage left">
                                    <div className="supportMessageHeader dFlex">  
                                        <div className="supportMessageHeaderLeft dFlex">
                                            <div>
                                                <Icon type='avatar'></Icon> 
                                            </div>
                                            <div
                                                onClick={() => {
                                                }}
                                            >
                                                <div className="supportMessageHeaderLeftHead">
                                                    {el.name}
                                                </div>    
                                                <div className="supportMessageHeaderLeftSec">
                                                    {list[el.role]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="supportMessageHeaderRight dFlex">
                                            <div className="dFlex">
                                                <Icon type='time'></Icon> 
                                                {getDate(el.createdAt?.replace('T',' '),'hh:mm',':')}
                                            </div>
                                            <div style={{marginLeft:'18px'}} className="dFlex">
                                                <Icon type='calendar'></Icon>
                                                {getDate(el.createdAt?.replace('T',' '),'dd/mm/yyyy','.')}                                            
                                            </div>                               
                                        </div>
                                    </div>
                                    <div className="supportMessageBody">
                                        {el.message}
                                    </div>
                                    {el?.img?.length>0 && el?.img[0]!==null && <div className="supportMessageFooter">
                                        <div className="supportMessageHeaderLeftHead">Вкладення ({el?.img?.length})</div>
                                        <div className="supportMessageFooterList">
                                            {el?.img?.length>0 && el?.img.map(elem=>{
                                                return(
                                                <div className="dFlex" onClick={()=>window.open(elem.url)}>
                                                    <div><Icon type='download'></Icon></div>
                                                    <div>{elem.name}</div>
                                                </div>
                                                )
                                            })}
                                        </div>
                                    
                                    </div>}

                                </div>
                                :
                                <div className="supportMessage right">
                                    <div className="supportMessageHeader dFlex">  
                                        <div className="supportMessageHeaderLeft dFlex">
                                            <div>
                                                <Icon type='avatar'></Icon> 
                                            </div>
                                            <div>
                                                <div className="supportMessageHeaderLeftHead">
                                                    {el.name}
                                                </div>    
                                                <div className="supportMessageHeaderLeftSec">
                                                    {list[el.role]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="supportMessageHeaderRight dFlex">
                                            <div className="dFlex">
                                                <Icon type='time'></Icon> 
                                                {getDate(el.createdAt?.replace('T',' '),'hh:mm',':')}
                                            </div>
                                            <div style={{marginLeft:'18px'}} className="dFlex">
                                                <Icon type='calendar'></Icon>
                                                {getDate(el.createdAt?.replace('T',' '),'dd/mm/yyyy','.')}                                            
                                            </div>                               
                                        </div>
                                    </div>
                                    <div className="supportMessageBody">
                                        {el.message}
                                    </div>
                                    <div className="supportMessageFooter">
                                       <div className="supportMessageHeaderLeftHead">Вкладення ({el?.img?.length || '0'})</div>
                                        <div className="supportMessageFooterList">
                                            {el?.img?.length>0 && el.img.map(elem=>{
                                                return(
                                                <div className="dFlex" onClick={()=>window.open(elem.url)}>
                                                    <div><Icon type='download'></Icon></div>
                                                    <div>{elem.name}</div>
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>}
                            </>
                           )
                       }) }

                    </div>


                </div>




        </div>}
    </>)

}

export default SupportItemPage;