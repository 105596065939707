import {
  SET_MESSAGE
  } from "./types";
  
  import NotificationsService from "../services/notifications.service";
  
  export const getNotifications = (data) => (dispatch) => {
    return NotificationsService.getNotifications(data).then(
      (response) => {
        return response;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  }






