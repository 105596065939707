import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../Icon';


const Header=(props)=>{
    const { t } = useTranslation();
    const navigate = useNavigate();

    const setTopicClick=(topic)=>{
        props.setTopic(topic);
        localStorage.setItem('Theme',topic);
    }

    return(
        <div className='headerPosition'>
            <div className='header prFlexRow'>
                <div className='headerLogo'  >
                    <div className='headerLogoLogo' onClick={()=>navigate('/')}>
                        <Icon type='logo'></Icon>
                    </div>
                    
                    <div className="headerLogoText">
                        <Icon type="rivalsLogoText"/>
                    </div>

                    <div className='headerTheme'>
                        <div onClick={()=>setTopicClick('black')} className={props.topic=='white'?'selected':'selected active'}>
                            <Icon type='darkTheme'></Icon>
                        </div>
                        <div onClick={()=>setTopicClick('white')} className={props.topic=='black'?'selected':'selected active'}>
                            <Icon type='lightTheme'></Icon>
                        </div>
                    </div>            
                </div>
                <div className='headerButtons prFlexRow'>

                    <button className='prButton prButtonMain' onClick={()=>navigate('/login')} >
                        {t("auth_login" )}
                    </button>
                    <button className='prButton prButtonMain register' style={{marginRight:'13px'}} onClick={()=>navigate('/registration')}>
                        {t("auth_registration")}
                    </button>
                    <div>
                        <Icon type='more'></Icon>
                    </div>
                    
                </div>
            </div>
        </div>
    )
};

export default Header;