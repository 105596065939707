import {
    GETSUPPORTDETAIL_FAIL,
    GETSUPPORTDETAIL_SUCCESS,
    GETSUPPORTSLIST_FAIL,
    GETSUPPORTSLIST_SUCCESS,
    CREATESUPPORT_FAIL,
    CREATESUPPORT_SUCCESS,
    EDITSUPPORT_SUCCESS,
    EDITSUPPORT_FAIL,
    ERROR,
    } from "./types";
    
    import SupportService from "../services/support.service";
    
      export const getSupportDetail =(form)=>(dispatch)=>{
          return SupportService.getSupportDetail(form).then( (response) => {
              if(response.statusText!="OK" || response.data.errors){
                dispatch({
                  type: GETSUPPORTDETAIL_FAIL,
                  payload: response.data,
                });
              return response.data;
              }else{
                dispatch({
                  type: GETSUPPORTDETAIL_SUCCESS,
                  payload: response.data,
                });
                return  response.data;
              }
            },
            (error) => {
              dispatch({
                type: GETSUPPORTDETAIL_FAIL,
              });
              dispatch({
                type: ERROR,
                message:error.message
            });
            console.log(error);
              return Promise.reject();
            }
          );
      }

    export const getSupportsList=(form)=>(dispatch)=>{
      return SupportService.getSupportsList(form).then( (response) => {
          if(response.statusText!="OK" || response.data.errors){
            dispatch({
              type: GETSUPPORTSLIST_FAIL,
              payload: response.data,
            });
          return response.data;
          }else{
            dispatch({
              type: GETSUPPORTSLIST_SUCCESS,
              payload: response.data,
            });
            return  response.data;
          }
        },
        (error) => {
          dispatch({
            type: GETSUPPORTSLIST_FAIL,
          });
          dispatch({
            type: ERROR,
            message:error.message
        });
        console.log(error);
          return Promise.reject();
        }
      );
  }
  




  export const createSupport=(form)=>(dispatch)=>{
    return SupportService.createSupport(form).then( (response) => {
          dispatch({
            type: CREATESUPPORT_SUCCESS,
            payload: response.room,
          });
          return  response.room;
      },
      (error) => {
        dispatch({
          type: CREATESUPPORT_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
  }




  export const editSupport=(form)=>(dispatch)=>{
    return SupportService.editSupport(form).then( (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: EDITSUPPORT_FAIL,
            payload: response.SUPPORT,
          });
          return response.SUPPORT;
        }else{
          dispatch({
            type: EDITSUPPORT_SUCCESS,
            payload: response.SUPPORT,
          });
          return  response.SUPPORT;
        }
      },
      (error) => {
        dispatch({
          type: EDITSUPPORT_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
        console.log(error);
        return Promise.reject();
      }
    );
  }
  

  export const createMessageSupport=(form)=>(dispatch)=>{
    return SupportService.createMessageSupport(form).then( (response) => {
          return  response;
      },
      (error) => {
        dispatch({
          type: EDITSUPPORT_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
        console.log(error);
        return Promise.reject();
      }
    );
  }
  
  
  
  
    
  
  
    
    
    