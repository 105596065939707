import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';

const getHints = (body) => {
  return axios
  .get(`${constants.URL}hints`, { headers: authHeader() })
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return  Promise.reject(e.response.data);
  });
}


const editHints = (data)=>{
  return axios.put(constants.URL + 'hints/'+data.id,{...data},{ headers: authHeader()})
  .then((response) => { 
      return response.data})
  .catch((e)=>{   
      console.log(e);
  });
}

export default {
  getHints,
  editHints
}