import React, { useState, useEffect, useRef } from "react";

import { Icon } from "../Icon";

// It have to receive the options prop that should look like this:
// { id: string, title: string }
const Select = ({
  id = '',
  placeholder = 'Оберіть опцію',
  options = [],
  value = '',
  onChange = () => {},
}) => {
  const [isOpen, setIsOpen] = useState();
  const inputRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  }

  const toggleSelection = (option) => {
    const e = {
      target: {
        id: id,
        value: option.id
      }
    };

    onChange(e);
  }

  useEffect(() => {
    const handleClick = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    }
  }, [])

  return (
    <div className={`multiselect ${isOpen && 'focused'}`} onClick={toggleDropdown} ref={inputRef}>
      <div className="prInputInput">
        {!value ? (
          placeholder
        ) : (
          <span>
            {options.find( option => option.id === value).title}
          </span>
        )}
        <Icon type="selectArrow" />
      </div>
      {isOpen && (
        <ul className="multiselectOptions">
          {
            options.length > 0 && options.map(option => (
              <li
                key={option.id}
                className="multiselectOption"
                onClick={() => toggleSelection(option)}
              >
                {option.title}
              </li>
            ))
          }
        </ul>
      )}
    </div>
  )
}

export default Select;