import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../../components/FileUpload/FileUpload";
import { createSupport } from "../../../redux/actions/support";
import Select from "../../../components/Select/Select";



const SupportCreatePage =()=>{
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form,setForm]=useState({});
    const [select,setSelect]=useState('09ba716e-3597-4751-84b3-8fdf453cd042');
    const user = useSelector((state) => state.currentUser.user);
    const fullList = useSelector((state) => state.fullList);

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
    };

    const createClick=()=>{
        let data={...form};
        if(!data.email || data.email?.length<1){
            data.email=user.email;
        }
        data.department=select;
        if(!data.name || data.name?.length<1){
            data.name=user.lastName + ' ' + user.firstName;
        }
        data.topic=1;
        data.status = '09ba716e-3597-4751-84b3-8fdf453cd011';
        dispatch(createSupport(data)).then(res=>{
            navigate('/supportRoom/'+res.id);
        })
    }


    return (
        <>
            <div className="profileSection createSupportPage" style={{marginBottom:'0px'}}>
                <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
                    <h2 >
                        Новий запит
                    </h2>
                </div>

                <div className="dFlex" style={{gap:'21px'}}>
                    <div className="supportAlign supportAlignCenter">
                        <div className="createSupport createSupportLeft">
                            <div className="createSupportHeader">Інформація для ознайомлення</div>
                            <div className="createSupportText">
                                Тут буде інформція про особливості роботи відділу технічної підтримки
                            </div>
                            <div className="createSupportText">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                            </div>
                        </div>
                        <button className="prButton prButtonMain" style={{margin:'34px 0',alignSelf:'center'}} onClick={()=>navigate('/supportList')}>До списку запитів</button>
                    </div>
                   
                    <div className="supportAlign">
                        <div className="createSupport">
                            <div className="createSupportHeader">
                                Заповність форму звернення
                            </div>
                            <div className="createSupportForm">
                                <div className="createSupportFormLabel">
                                    Оберіть відділ
                                </div>
                                <div className="prFlexRow">
                                    <div className={select=='09ba716e-3597-4751-84b3-8fdf453cd042'?"forSelect active":"forSelect"}>
                                        <div className="createSelectItem" onClick={()=>setSelect('09ba716e-3597-4751-84b3-8fdf453cd042')}>
                                            Технічний відділ
                                        </div>
                                        <div className="createSelectText">
                                            Технічні несправності та інші нюанси роботи із Системою
                                        </div>
                                    </div>
                                    <div className={select=='09ba716e-3597-4751-84b3-8fdf453cd041'?"forSelect active":"forSelect"}>
                                        <div className="createSelectItem" onClick={()=>setSelect('09ba716e-3597-4751-84b3-8fdf453cd041')}>
                                            Фінансовий відділ
                                        </div>
                                        <div className="createSelectText">
                                            Питання оплати, ведденя, виведення коштів
                                        </div>
                                    </div>
                                </div>
                                <div className="dFlex createFormInputs">
                                    <div className="prInput">
                                        <label htmlFor="name" className={"prInputLabel"}>{t("Ім’я")}</label>
                                        <input onChange={changeHandler} value={form.name || (user?.firstName?.length>0  && user?.lastName?.length>0 &&user?.lastName+' '+ user?.firstName)||''} id="name" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                                    </div>
                                    <div className="prInput">
                                        <label htmlFor="email" className={"prInputLabel"}>{t("Email")}</label>
                                        <input onChange={changeHandler} value={form.email || user.email} id="email" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                                    </div>
                                    <div className="prInput">
                                        <label htmlFor="priority" className={"prInputLabel"}>{t("Приорітет")}</label>
                                        <Select
                                            id="priority"
                                            value={form.priority || ''}
                                            onChange={changeHandler}
                                            placeholder="Нічого не обрано"
                                            options={
                                                fullList.Priority.length > 0 &&
                                                fullList.Priority
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="prInput">
                                    <label htmlFor="message" className={"prInputLabel"}>{t("Напишіть повідомлення")}</label>
                                    <textarea onChange={changeHandler} id="message" value={form.message} placeholder={t("enter_value")} cols="30" rows="5"></textarea>
                                </div>
                            </div>
                            <div className="createSupportText">
                                Ви можете завантажити до 3 файлів у одному повідомленні. Якщо хочете передати нам більше файлів, можете використати посилання на Dropbox чи Google Drive
                            </div>
                            <div>
                                <FileUpload id={'img'} form={form} setForm={setForm} maxFiles={3}></FileUpload>
                            </div>
                        </div>

                        <div className="buttonsGap">
                            <button className="prButton prButtonSecond" onClick={()=>navigate('/supportList')}>Відмінити</button>
                            <button className="prButton prButtonMain" onClick={createClick}>Надіслати</button>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}


export default SupportCreatePage;