import {
  GETTRANSACTIONDETAIL_FAIL,
  GETTRANSACTIONDETAIL_SUCCESS,
  GETTRANSACTIONSLIST_FAIL,
  GETTRANSACTIONSLIST_SUCCESS,
  CREATETRANSACTION_SUCCESS,
  CREATETRANSACTION_FAIL,
  EDITTRANSACTION_FAIL,
  EDITTRANSACTION_SUCCESS
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETTRANSACTIONDETAIL_SUCCESS:
      return {
        ...state,
        currentTransaction:payload
      };
    case GETTRANSACTIONDETAIL_FAIL:
      return {
        ...state
      };
    case GETTRANSACTIONSLIST_FAIL:
      return {
        ...state
      };
    case GETTRANSACTIONSLIST_SUCCESS:
      return {
        list:[...payload.transactions],
        total:payload.total,
        totalFilter:payload.totalTotal,
        limit:payload.limit,
        offset:payload.offset
      };
    case CREATETRANSACTION_SUCCESS:
     return {
        ...state,
        transaction:{...payload}
      };
    case CREATETRANSACTION_FAIL:
      return {
        ...state
      };
    case EDITTRANSACTION_FAIL:
      return {
        ...state
      };
    case EDITTRANSACTION_SUCCESS:
      return {
        ...state
      };
    default:
      return state;
  }
}
