import axios from "axios";

import authHeader from "./auth-header";
import constants from '../../constants/constant.json';

const getNotifications = (data) => {
    let filter = ''
    if(data?.limit>0){
        filter+='limit='+data.limit;
    }
    if(data?.offset>0){
      filter+='&offset='+data.offset;
    }else{
      filter+='&offset=0';
    }
    if (data.filter && Object.keys(data.filter)?.length>0) {
        for (const [key, value] of Object.entries(data?.filter)) {
            if(value.length>0){
                filter += `&filters[${key}]=${value}`
              }
            }
          }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `notification?${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;

}


export default {
    getNotifications,
}