import {
  GETALLLOGS_SUCCESS,
  GETALLLOGS_FAIL,
  ERROR
} from "./types";

import LogService from '../services/log.service'

export const getLogs = (form) => (dispatch) => {
    return LogService.getLogs(form).then( (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: GETALLLOGS_FAIL,
            payload: response.data,
          });
        return response.data;
        }else{
          dispatch({
            type: GETALLLOGS_SUCCESS,
            payload: response.data,
          });
          return  response.data;
        }
      },
      (error) => {
        dispatch({
          type: GETALLLOGS_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
}