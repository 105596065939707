import {
  GETUSERDETAIL_FAIL,
  GETUSERDETAIL_SUCCESS,
  GETUSERSLIST_FAIL,
  GETUSERSLIST_SUCCESS,
  CREATEUSER_SUCCESS,
  CREATEUSER_FAIL,
  EDITUSER_FAIL,
  EDITUSER_SUCCESS,
  CHANGEUSERPASSWORD_SUCCESS,
  CHANGEUSERPASSWORD_FAIL,
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETUSERDETAIL_SUCCESS:
      return {
        ...state,
        currentUser:payload
      };
    case GETUSERDETAIL_FAIL:
      return {
        ...state
      };
    case GETUSERSLIST_FAIL:
      return {
        ...state
      };
    case GETUSERSLIST_SUCCESS:
      return {
        list:[...payload.users],
        total:payload.total,
        totalFilter:payload.totalFilter,
        limit:payload.limit,
        offset:payload.offset
      };
    case CREATEUSER_SUCCESS:
     return {
        ...state,
        user:{...payload}
      };
    case CREATEUSER_FAIL:
      return {
        ...state
      };
    case EDITUSER_FAIL:
      return {
        ...state
      };
    case EDITUSER_SUCCESS:
      return {
        ...state
      };
    case CHANGEUSERPASSWORD_SUCCESS:
      return {
        ...state
      }
    case CHANGEUSERPASSWORD_FAIL:
      return {
        ...state
      }
    default:
      return state;
  }
}
