import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { Icon } from "../../../components/Icon";
import getDate from "../../../helpers/getDate";
import { editReputation, editUser, getReputation, getUserDetail, userEvent, changeUserPassword } from "../../../redux/actions/user";
import Select from "../../../components/Select/Select";
import Hint from "../../../components/Hint/Hint";

const AdminProfileSettingsPage =()=>{
    const { t } = useTranslation();
    const [form,setForm]=useState({});
    const [reputation,setReputation]=useState({});
    const [edit,setEdit]=useState(false);
    const [errors, setErrors] = useState({});
    const [showRepeat, setShowRepeat] = useState(false);
    const [show, setShow] = useState(false);
    const [showOld, setShowOld] = useState(false);
    const fullList = useSelector((state) => state.fullList);
    const list = useSelector((state) => state.list);
    const hint = useSelector((state) => state.hints);
    
    const id =useParams().id;
    const userCur = useSelector((state) => state.currentUser.user);
    const user = useSelector((state) => state.user.currentUser?.user);
    const [selectedType, setSelectedType] = useState("add");


    const [validForm, setValidForm] = useState({});
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);

    const changeHandler = (event) => {
        let { id, value } = event.target;

        console.log('id', id);

        id = id && id.includes('Tablet') ? id.slice(0, -6) : id;

        if (event.target.tagName === 'SELECT' && event.target.accessKey) {
            setForm({ ...form, [event.target.accessKey]: value });
        } else {
            setForm({ ...form, [id]: value });
        }
    };

    const handleChange = (event) => {
        setSelectedType(event.target.value);
      };
  

    const checkHandler = (event) => {
        const emailCheck =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{8,30}$/;
        let { id, value } = event.target;

        id = id && id.includes('Tablet') ? id.slice(0, -6) : id;

        switch (id) {
            case "email":
                if (emailCheck.test(String(value).toLowerCase())) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_email") });
                }
                break;
            case "nickname":
                if (value.length >= 3) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_nickname") });
                }
                break;
            case "code":
                if (value.length >= 6) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_code") });
                }
                break;
            case "passwordNew":
                if (passwordCheck.test(String(value))) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_password") });
                }
                break;
            case "passwordRepeat":
                if (value !== form.passwordNew) {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("password_mismatch") });
                } else {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                }
                break;
            default:
                setValidForm({ ...validForm, [id]: true });
                setErrors({ ...errors, [id]: "" });
        }
    };

    const declineClick = () => {
        setEdit(false);
    };

    const initFunc = () => {
        let data={};
        data.id=id;
        dispatch(getUserDetail(data)).then(res=>{
            console.log('RESPONSE: ', res);   
        })
        if(id){
            dispatch(getReputation(data)).then(res=>{
                setReputation({...res});
                setForm({...user});
                setReady(true);
            })
        }
    };

    const userEventClick=(e)=>{
        let data={};
        data.type = e.target.id;
        data.id=id;
        dispatch(userEvent(data)).then(res=>{
            initFunc();
        })
    }

    const reputationChange =(e)=>{
        let data={};
        data.id=id;
        data.type=selectedType;
        data.value=form.repAmount;
        data.description=form.description;
        dispatch(editReputation(data)).then(res=>{
            initFunc();

            setForm({
                ...form,
                repAmount: 0,
                description: '',
            })
        })
    }

    const changePassword = () => {
        const data = {
            'email': user.email,
            'passwordOld': form.passwordOld,
            'passwordNew': form.passwordNew,
        }

        dispatch(changeUserPassword(data))
            .then((res) => {
                console.log(res);
            })
            .catch(e => {
                console.log('Error: ', e);
            })
    }

    const saveClick = () => {
        let data = { ...form };
        dispatch(editUser(data)).then(res => {
            setEdit(false);
            setForm({...res});
        }).catch(e => {
            console.log(e);
        });
    };

    useEffect(() => {
        if (!ready) {
            initFunc();
        }
    }, [ready,user]);

    return(
        <>
            {ready && (
                <div className="profileSectionBlock prFlexRow userSettingsBlock">
                    <div className="profileSettingsTabletBlock">
                        <div className="adminPanel">
                            <button className="prButtonMain" onClick={userEventClick} id='block'>Заблокувати</button>
                            <button className="prButtonMain" onClick={userEventClick} id='unBlock' >Розблокувати</button>
                            <button className="prButtonMain" onClick={userEventClick} id='verified'>Верифікувати</button>
                            <button className="prButtonMain" onClick={userEventClick} id='unVerified'>Відхилити верифікацію</button>
                            <button className="prButtonMain" onClick={userEventClick} id='deleteInfo'>Видалити персональні дані</button>
                            <button className="prButtonMain" onClick={userEventClick} id='restoreInfo'>Відновити персональні дані</button>
                        </div>
                        <div className="prFlexRow">
                            <div className="userEditLeft" style={{marginBottom:'20px'}}>
                                    <h2>{t('user_info')}
                                        <div className="infoHelp">
                                            <Hint align='left' item={hint[13]}/>
                                        </div>
                                    </h2>
                                    {!edit && <div className="editButton" onClick={()=>setEdit(true)}><Icon type='edit' /></div>}
                                    {edit?<div className="prInput">
                                            <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                            <input value={form?.nickname} onChange={changeHandler} onBlur={checkHandler} id="nickname" type="text" className={errors.nickname ? "prInputInput inputError" : "prInputInput"} placeholder={t("nickname")} />
                                            {errors.nickname && <div className="error">{errors.nickname}</div>}
                                        </div>:
                                        <div className="prInput">
                                            <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                            <div className="noEditField">{user?.nickname}</div>
                                        </div>
                                    }
                                    {edit?<div className="prInput">
                                            <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                            <input value={form?.email} onChange={changeHandler} onBlur={checkHandler} id="email" type="text" className={errors.email ? "prInputInput inputError" : "prInputInput"} placeholder={t("mail")} />
                                            {errors.email && <div className="error">{errors.email}</div>}
                                        </div>:
                                        <div className="prInput">
                                            <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                            <div className="noEditField">{user?.email}</div>
                                        </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                        <DatePicker id='birthday'  onChange={changeHandler} icon={true} value={form.birthday} />
                                        {errors.birthday && <div className="error">{errors.birthday}</div>}
                                    </div>:
                                        <div className="prInput">
                                            <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                        <div className="noEditField">{user?.birthday|| '---'}</div>
                                    </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                        <Select
                                            id="sex"
                                            value={form.sex || ''}
                                            onChange={changeHandler}
                                            placeholder="Нічого не обрано"
                                            options={
                                                fullList.Sex.length > 0 &&
                                                fullList.Sex
                                            }
                                        />
                                        {errors.sex && <div className="error">{errors.sex}</div>}
                                    </div>:
                                    <div className="prInput">
                                        <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                        <div className="noEditField">{(user?.sex && list[user?.sex])|| '---'}</div>
                                    </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                        <input value={form.firstName} onChange={changeHandler} onBlur={checkHandler} id="firstName" type="text" className={errors.firstName ? "prInputInput inputError" : "prInputInput"} placeholder={t("first_name")} />
                                        {errors.firstName && <div className="error">{errors.firstName}</div>}
                                    </div>:
                                        <div className="prInput">
                                            <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                            <div className="noEditField">{user?.firstName|| '---'}</div>
                                        </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                        <input value={form.lastName} onChange={changeHandler} onBlur={checkHandler} id="lastName" type="text" className={errors.lastName ? "prInputInput inputError" : "prInputInput"} placeholder={t("last_name")} />
                                        {errors.lastName && <div className="error">{errors.lastName}</div>}
                                    </div>:
                                        <div className="prInput">
                                        <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                        <div className="noEditField">{user?.lastName|| '---'}</div>
                                    </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                        <Select
                                            id="country"
                                            value={form.country || ''}
                                            onChange={changeHandler}
                                            placeholder="Нічого не обрано"
                                            options={[
                                                { id: '', title: t('empty_select') },
                                                { id: 'Україна', title: 'Україна' },
                                                { id: 'Інша країна', title: 'Інша країна' },
                                            ]}
                                        />
                                        {errors.country && <div className="error">{errors.country}</div>}
                                    </div>:
                                        <div className="prInput">
                                        <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                        <div className="noEditField">{user?.country|| '---'}</div>
                                    </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="time_line" className={errors.time_line ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                        <Select
                                            id="time_line"
                                            value={form.time_line || ''}
                                            onChange={changeHandler}
                                            placeholder="Нічого не обрано"
                                            options={[
                                                { id: '', title: t('empty_select') },
                                                { id: '+1', title: '+1' },
                                                { id: '+2', title: '+2' },
                                            ]}
                                        />
                                        {errors.time_line && <div className="error">{errors.time_line}</div>}
                                    </div>:
                                        <div className="prInput">
                                        <label htmlFor="time_line" className={errors.time_line ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                        <div className="noEditField">{user?.time_line|| '---'}</div>
                                    </div>
                                    }
                                {edit&&<div className="userEditButtons">
                                        <button className="prButton prButtonSecond" onClick={declineClick}>{t('decline')}</button>
                                        <button className="prButton prButtonMain" onClick={saveClick}>{t('save')}</button>
                                    </div>}
                            </div>
                            <div className="userEditLeft">
                            <h2>{t('reputation')}
                                        <div className="infoHelp">
                                            <Hint align='left' item={hint[15]}/>
                                        </div>
                            </h2>
        
                            <div className="dFlex jCC" style={{gap:'24px'}}>
                                <div className={(user?.reputation>6 && "circleReputationSettings") || (user?.reputation<7 && user?.reputation>2 && "circleReputationSettings orange") ||(user?.reputation<3 && "circleReputationSettings red")}>
                                    {user?.reputation}
                                </div>
                                <div className="reputationRightText">
                                    Тут місце для тексту із важливою інформацією. Якщо її не буде - то тут нічого не виводиться.
                                </div>
                            </div>
        
                            <h4 className="reputationH4">{t('reputation')}
                                <div className="infoHelp">
                                    <Hint align='left' item={hint[16]}/>
                                </div>
                            </h4>
                            <div>
                                {reputation?.data?.length>0 && reputation?.data.map(el=>{
                                    return(
                                <div className={`reputationListItem dFlex ${el.symbol=='-'?'red':'green'}`} style={{gap:'20px'}}>
                                    <div className="circleReputationSettingsSmall">
                                        {el.symbol}
                                    </div>
                                    <div>
                                        <div className="reputationListItemValue">
                                            {el.symbol}{el.title} {el.title=='1'?'бал':'бали'}
                                        </div>
                                        <div className="reputationListItemRemark">
                                            {el.description}
                                        </div>
                                    </div>
                                </div>)
                                })}
                            </div>
                            </div>
                        </div>
                        <div className="prFlexRow">
                            <div className="userEditLeft">
                                <h2>{t('change_password')}
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[18]}/>
                                    </div>
                                </h2>
                                <div className="prInput prInputIcon">
                                    <label htmlFor="passwordOldTablet" className={errors.passwordOld ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                                    <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="passwordOldTablet" type={showOld ? 'text' : "password"} className={errors.passwordOld ? "prInputInput inputError" : "prInputInput"} placeholder={t("old_password")} />
                                    <div className="prInputIconIcon" onClick={() => setShowOld(!showOld)}><Icon type={showOld ? 'eyeOpen' : 'eyeClosed'} /></div>
                                    {errors.passwordOld && <div className="error">{errors.passwordOld}</div>}
                                </div>
                                <div className="prInput prInputIcon">
                                    <label htmlFor="passwordNewTablet" className={errors.passwordNew ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                                    <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="passwordNewTablet" type={show ? 'text' : "password"} className={errors.passwordNew ? "prInputInput inputError" : "prInputInput"} placeholder={t("password")} />
                                    <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                                    {errors.passwordNew && <div className="error">{errors.passwordNew}</div>}
                                </div>
                                <div className="prInput prInputIcon">
                                    <label htmlFor="passwordRepeatTablet" className={errors.passwordRepeat ? "prInputLabel inputError" : "prInputLabel"}>{t("password_repeat")}</label>
                                    <input minLength={8} onChange={changeHandler} onBlur={checkHandler} id="passwordRepeatTablet" type={showRepeat ? 'text' : "password"} className={errors.passwordRepeat ? "prInputInput inputError" : "prInputInput"} placeholder={t("password_repeat")} />
                                    <div className="prInputIconIcon" onClick={() => setShowRepeat(!showRepeat)}><Icon type={showRepeat ? 'eyeOpen' : 'eyeClosed'} /></div>
                                    {errors.passwordRepeat && <div className="error">{errors.passwordRepeat}</div>}
                                </div>
                                <button
                                    style={{margin:'13px 0 13px 47px'}}
                                    className="prButton prButtonMain"
                                    onClick={changePassword}
                                >
                                    {t('save_password')}
                                </button>
                            </div>
                            <div className="userEditLeft" style={{marginBottom:'20px'}}>
                        <h2>{t('personal_info')}
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[14]}/>
                                    </div>
                        </h2>
                        <div className="simpleTextPersonal mb">
                            {getDate(new Date(),'dd/mm/yyyy','.')}
                        </div>
                        <div className="simpleHeaderPersonal">
                            {t("last_activity")}
                        </div>
                        <div className="simpleTextPersonal mb">
                            {getDate(new Date(),'dd/mm/yyyy','.')}
                        </div>
                        <div className="simpleHeaderPersonal" style={{marginBottom:'11px'}}>
                            {t("files_for_verification")}
                        </div>
                        <div className="simpleTextPersonal mb dFlex" style={{'gap':'7px'}}>
                            {
                                user?.verificationData.photos && user?.verificationData.photos.map( photo => (
                                    <a href={photo.url} target="_blank" rel="noreferrer">
                                        <div className="documentImage">
                                            <img src={photo.url} alt={photo.name} />
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                        <div className="simpleHeaderPersonal">
                            {t("last_ips")}
                        </div>
                        <div className="simpleTextPersonal">
                            000.000.000.0
                        </div>
                        <div className="simpleTextPersonal">
                            000.000.000.0
                        </div>
                            </div>
                        </div>
                        <div className="userEditLeft">
                            <h2>{t('delete_personal_info')}
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[17]}/>
                                    </div>
                            </h2>
                            <div className="simpleTextPersonal mb">
                                {t('delete_1')}
                            </div>
                            <div className="simpleTextPersonal mb">
                                {t('delete_2')}
                            </div>
                            <button className="prButton prButtonMain" style={{margin:"20px"}}>
                                {t('delete_personal_button')}
                            </button>
                        </div>
                    </div> 
                    <div>
                        <div className="adminPanel">
                            <button className="prButtonMain" onClick={userEventClick} id='block'>Заблокувати</button>
                            <button className="prButtonMain" onClick={userEventClick} id='unBlock' >Розблокувати</button>
                            <button className="prButtonMain" onClick={userEventClick} id='verified'>Верифікувати</button>
                            <button className="prButtonMain" onClick={userEventClick} id='unVerified'>Відхилити верифікацію</button>
                            <button className="prButtonMain" onClick={userEventClick} id='deleteInfo'>Видалити персональні дані</button>
                            <button className="prButtonMain" onClick={userEventClick} id='restoreInfo'>Відновити персональні дані</button>
                        </div>
                        <div className="userEditLeft" style={{marginBottom:'20px'}}>
                            <h2>{t('personal_info')}
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[14]}/>
                                    </div>
                            </h2>
                            <div className="simpleTextPersonal mb">
                                {getDate(new Date(),'dd/mm/yyyy','.')}
                            </div>
                            <div className="simpleHeaderPersonal">
                                {t("last_activity")}
                            </div>
                            <div className="simpleTextPersonal mb">
                                {getDate(new Date(),'dd/mm/yyyy','.')}
                            </div>
                            <div className="simpleHeaderPersonal" style={{marginBottom:'11px'}}>
                                {t("files_for_verification")}
                            </div>
                            <div className="simpleTextPersonal mb dFlex" style={{'gap':'7px'}}>
                                {
                                    user?.verificationData.photos && user?.verificationData.photos.map( photo => {
                                        <div className="documentImage">
                                            <img src={photo.url} alt={photo.name} />
                                        </div>
                                    })
                                }
                            </div>
                            <div className="simpleHeaderPersonal">
                                {t("last_ips")}
                            </div>
                            <div className="simpleTextPersonal">
                                000.000.000.0
                            </div>
                            <div className="simpleTextPersonal">
                                000.000.000.0
                            </div>
                        </div>
                    </div> 
                    <div style={{flex:'0 0 32%'}}>
                        <div className="userEditLeft" style={{marginBottom:'20px'}}>
                            <h2>{t('user_info')}
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[13]}/>
                                    </div>
                            </h2>
                            {!edit && <div className="editButton" onClick={()=>setEdit(true)}><Icon type='edit' /></div>}
                            {edit?<div className="prInput">
                                    <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                    <input value={form?.nickname} onChange={changeHandler} onBlur={checkHandler} id="nickname" type="text" className={errors.nickname ? "prInputInput inputError" : "prInputInput"} placeholder={t("nickname")} />
                                    {errors.nickname && <div className="error">{errors.nickname}</div>}
                                </div>:
                                <div className="prInput">
                                    <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                    <div className="noEditField">{user?.nickname}</div>
                                </div>
                            }
                            {edit?<div className="prInput">
                                    <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                    <input value={form?.email} onChange={changeHandler} onBlur={checkHandler} id="email" type="text" className={errors.email ? "prInputInput inputError" : "prInputInput"} placeholder={t("mail")} />
                                    {errors.email && <div className="error">{errors.email}</div>}
                                </div>:
                                <div className="prInput">
                                    <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                    <div className="noEditField">{user?.email}</div>
                                </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                <DatePicker id='birthday'  onChange={changeHandler} icon={true} value={form.birthday} />
                                {errors.birthday && <div className="error">{errors.birthday}</div>}
                            </div>:
                                <div className="prInput">
                                    <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                <div className="noEditField">{user?.birthday|| '---'}</div>
                            </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                <Select
                                    id="sex"
                                    value={form.sex || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={
                                        fullList.Sex.length > 0 &&
                                        fullList.Sex
                                    }
                                />
                                {errors.sex && <div className="error">{errors.sex}</div>}
                            </div>:
                            <div className="prInput">
                                <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                <div className="noEditField">{(user?.sex && list[user?.sex])|| '---'}</div>
                            </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                <input value={form.firstName} onChange={changeHandler} onBlur={checkHandler} id="firstName" type="text" className={errors.firstName ? "prInputInput inputError" : "prInputInput"} placeholder={t("first_name")} />
                                {errors.firstName && <div className="error">{errors.firstName}</div>}
                            </div>:
                                <div className="prInput">
                                    <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                    <div className="noEditField">{user?.firstName|| '---'}</div>
                                </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                <input value={form.lastName} onChange={changeHandler} onBlur={checkHandler} id="lastName" type="text" className={errors.lastName ? "prInputInput inputError" : "prInputInput"} placeholder={t("last_name")} />
                                {errors.lastName && <div className="error">{errors.lastName}</div>}
                            </div>:
                                <div className="prInput">
                                <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                <div className="noEditField">{user?.lastName|| '---'}</div>
                            </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                <Select
                                    id="country"
                                    value={form.country || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        { id: '', title: t('empty_select') },
                                        { id: 'Україна', title: 'Україна' },
                                        { id: 'Інша країна', title: 'Інша країна' },
                                    ]}
                                />
                                {errors.country && <div className="error">{errors.country}</div>}
                            </div>:
                                <div className="prInput">
                                <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                <div className="noEditField">{user?.country|| '---'}</div>
                            </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="time_line" className={errors.time_line ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                <Select
                                    id="time_line"
                                    value={form.time_line || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        { id: '', title: t('empty_select') },
                                        { id: '+1', title: '+1' },
                                        { id: '+2', title: '+2' },
                                    ]}
                                />
                                {errors.time_line && <div className="error">{errors.time_line}</div>}
                            </div>:
                                <div className="prInput">
                                <label htmlFor="time_line" className={errors.time_line ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                <div className="noEditField">{user?.time_line|| '---'}</div>
                            </div>
                            }
                        {edit&&<div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={declineClick}>{t('decline')}</button>
                                <button className="prButton prButtonMain" onClick={saveClick}>{t('save')}</button>
                            </div>}
                        </div>      
                        <div className="userEditLeft">
                            <h2>{t('change_password')}
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[18]}/>
                                    </div>
                            </h2>
                            <div className="prInput prInputIcon">
                                <label htmlFor="passwordOld" className={errors.passwordOld ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                                <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="passwordOld" type={showOld ? 'text' : "password"} className={errors.passwordOld ? "prInputInput inputError" : "prInputInput"} placeholder={t("old_password")} />
                                <div className="prInputIconIcon" onClick={() => setShowOld(!showOld)}><Icon type={showOld ? 'eyeOpen' : 'eyeClosed'} /></div>
                                {errors.passwordOld && <div className="error">{errors.passwordOld}</div>}
                            </div>
                            <div className="prInput prInputIcon">
                                <label htmlFor="passwordNew" className={errors.passwordNew ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                                <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="passwordNew" type={show ? 'text' : "password"} className={errors.passwordNew ? "prInputInput inputError" : "prInputInput"} placeholder={t("password")} />
                                <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                                {errors.passwordNew && <div className="error">{errors.passwordNew}</div>}
                            </div>
                            <div className="prInput prInputIcon">
                                <label htmlFor="passwordRepeat" className={errors.passwordRepeat ? "prInputLabel inputError" : "prInputLabel"}>{t("password_repeat")}</label>
                                <input minLength={8} onChange={changeHandler} onBlur={checkHandler} id="passwordRepeat" type={showRepeat ? 'text' : "password"} className={errors.passwordRepeat ? "prInputInput inputError" : "prInputInput"} placeholder={t("password_repeat")} />
                                <div className="prInputIconIcon" onClick={() => setShowRepeat(!showRepeat)}><Icon type={showRepeat ? 'eyeOpen' : 'eyeClosed'} /></div>
                                {errors.passwordRepeat && <div className="error">{errors.passwordRepeat}</div>}
                            </div>
                            <button
                                style={{margin:'13px 0 13px 47px'}}
                                className="prButton prButtonMain"
                                onClick={changePassword}
                            >
                                {t('save_password')}
                            </button>
                        </div>
                    </div>
                    <div style={{flex:'0 0 32%'}}>
                        <div className="userEditLeft" style={{marginBottom:'30px'}}>
                            
                            <h2>Редагувати&nbsp;репутацію
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[19]}/>
                                    </div>
                            </h2>
                            <div className="prInput prInputIcon">
                                <label htmlFor="repAmount" className={errors.repAmount ? "prInputLabel inputError" : "prInputLabel"}>{t("Кількість балів (цифрою)")}</label>
                                <input onChange={changeHandler} value={form.repAmount || ''} onBlur={checkHandler} id="repAmount" type={'text'} className={errors.repAmount ? "prInputInput inputError" : "prInputInput"} placeholder={t("enter_value")} />
                                {errors.repAmount && <div className="error">{errors.repAmount}</div>}
                            </div>
        
                            <h4 className="reputationH4">{t('Тип операції')}
                            </h4>
                            <div className="dFlex">
                                <label className="radioInput">
                                <input
                                    type="radio"
                                    name="documentType"
                                    value="add"
                                    checked={selectedType === "add"}
                                    onChange={handleChange}
                                    className='radioInputInput'
                                />
                                Додати
                                </label>
                                <label className="radioInput">
                                <input
                                    type="radio"
                                    name="documentType"
                                    value="remove"
                                    checked={selectedType === "remove"}
                                    onChange={handleChange}
                                    className='radioInputInput'
                                />
                                Відняти
                                </label>
                            </div>
                            <div className="prInput">
                                <label htmlFor="description" className={"prInputLabel dFlex"}>{t("Супровідне повідомлення")}
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[29]}/>
                                    </div>
                                </label>
                                <textarea onChange={changeHandler} id="description" value={form.description} placeholder={t("enter_value")} cols="30" rows="5"></textarea>
                            </div>
                            <button style={{margin:'13px 0 13px 72px'}} onClick={reputationChange} className="prButton prButtonMain">{t('Застовувати')}</button>
                        </div>
                        <div className="userEditLeft">
                            
                            <h2>{t('reputation')}
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[15]}/>
                                    </div>
                            </h2>
        
                            <div className="dFlex jCC" style={{gap:'24px'}}>
                                <div className={(user?.reputation>6 && "circleReputationSettings") || (user?.reputation<7 && user?.reputation>2 && "circleReputationSettings orange") ||(user?.reputation<3 && "circleReputationSettings red")}>
                                    {user?.reputation}
                                </div>
                                <div className="reputationRightText">
                                    Тут місце для тексту із важливою інформацією. Якщо її не буде - то тут нічого не виводиться.
                                </div>
                            </div>
        
                            <h4 className="reputationH4">{t('reputation')}
                                    <div className="infoHelp">
                                        <Hint align='left' item={hint[16]}/>
                                    </div>
                            </h4>
                            <div>
                                {reputation?.data?.length>0 && reputation?.data.map(el=>{
                                        return(
                                    <div className={`reputationListItem dFlex ${el.symbol=='-'?'red':'green'}`} style={{gap:'20px'}}>
                                        <div className="circleReputationSettingsSmall">
                                            {el.symbol}
                                        </div>
                                        <div>
                                            <div className="reputationListItemValue">
                                                {el.symbol}{el.title} {el.title=='1'?'бал':'бали'}
                                            </div>
                                            <div className="reputationListItemRemark">
                                                {el.description}
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>  
                </div>
            )}
        </>
    )

}

export default AdminProfileSettingsPage;