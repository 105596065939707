import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../components/Icon";
import { getHints, editHints } from "../../redux/actions/hints";

import Hint from '../../components/Hint/Hint';

const pagesIds = [
  {
    id: '1',
    title: 'Профіль',
  },
  {
    id: '2',
    title: 'Заголовки сторінок',
  },
  {
    id: '3',
    title: 'Випадаючі вікна',
  },
  {
    id: '4',
    title: 'Ігрова кімната',
  },
  {
    id: '5',
    title: 'Одинокі підказки',
  },
];

const HintsPage =()=>{
    const { t } = useTranslation();
    const [open,setOpen]=useState('');
    const [edit,setEdit]=useState('');
    const [form,setForm]=useState('');
    const hints = useSelector((state) => state.hints);
    const dispatch=useDispatch();

    const filterHints = (hint, pageId) => {
      switch (pageId) {
        case '1':
          return hint.id >= 1 && hint.id <= 30
        case '2':
          return (hint.id > 30 && hint.id <= 44)
        case '3':
          return hint.id > 44 && hint.id <= 59
        case '4':
          return hint.id > 59 && hint.id <= 70
        case '5':
          return hint.id >= 95 && hint.id <= 100
        default:
          return
      }
    };

    const changeHandler = (event) => {
      setForm({ ...form, [event.target.id]: event.target.value });
      };

    const openClick=(e)=>{
      if(e.currentTarget.id==open){
        setOpen('')
      }else{
        setOpen(e.currentTarget.id)
      }
    }
    const saveClick=(e)=>{
      let data={
        id: e.currentTarget.id,
        adminDescription: form.adminDescription,
        title: form.title,
        description: form.description,
      }

      dispatch(editHints(data)).then(res=>{
        dispatch(getHints()).then( res => {
          setEdit('');
        })
      })
    }

    const editClick=(e, hint)=>{
      setEdit(e.currentTarget.id)
      setForm({
        title: hint.title,
        description: hint.description,
        adminDescription: hint.adminDescription,
      })
    }

    console.log('hints length', hints.length);
    
    return(<>
      <div className="profileSection" style={{marginBottom:'0px'}}>
        <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
          <h2 >{t('Текстові константи')}
            <div className="infoHelp"> <Hint align='left' item={hints[37]}/></div>
          </h2>
        </div>

        {pagesIds && pagesIds.map(pageId => (
          <div key={pageId.id}>
            <div className="prFlexRow directoryGroup">
                <div id={pageId.id} onClick={openClick} className="prFlexRow directoryGroupName">
                  <div className={open==pageId.id?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                    <Icon type='arrowForward'></Icon>
                  </div>
                  <div>{pageId.title}</div>
                </div>
            </div>
            {open==pageId.id &&
              hints.length > 0 && hints.filter(hint => filterHints(hint, pageId.id)).map(el=>{
                return(
                  <div key={el.id} className="directoryElememt prFlexRow hintsElement">
                    {edit==el.id?
                      <>
                        <div className="prflexColumn" style={{ width: '100%'}}>
                          <div className="directoryElementText" style={{ fontWeight: 600 }}>
                              [constanta_{el.id}]
                          </div>
                          <div className="directoryElementText">
                            <input className="prInputInput" value={form.adminDescription || ''} id="adminDescription" onChange={changeHandler} placeholder="Опис для адміна" type="text" />
                          </div>
                        </div>
                        <div className="prflexColumn" style={{ width: '100%' }}>
                          <div className="directoryElementText">
                            <input className="prInputInput" value={form.title || ''} id="title" onChange={changeHandler} placeholder="Заголовок підказки" type="text" />
                          </div>
                          <textarea onChange={changeHandler} id="description" value={form.description || ''} placeholder={t("Текст підказки")} cols="30" rows="5"></textarea>
                        </div>
                        <div className="prFlexRow directoryElementButton">
                          <div className="prIconButton" id={el.id} onClick={saveClick}>
                            <Icon type='accept'></Icon>
                          </div>
                          <div className="prIconButton" onClick={()=>setEdit('')} >
                            <Icon type='close'></Icon>
                          </div>
                        </div>
                      </>: 
                      <>
                        <div className="prflexColumn" style={{ width: '100%'}}>
                          <div className="directoryElementText" style={{ fontWeight: 600 }}>
                            [constanta_{el.id}]
                          </div>
                          <div className="directoryElementText">
                            {el.adminDescription || 'Опис для адміністратора'}
                          </div>
                        </div>
                        <div className="prflexColumn" style={{ width: '100%'}}>
                          <div className="directoryElementText">
                            {el.title || 'Заголовок підказки'}
                          </div>
                          <div className="directoryElementText">
                            {el.description || 'Текст підказки'}
                          </div>
                        </div>
                        <div className="prFlexRow directoryElementButton">
                          <div className="prIconButton" id={el.id} onClick={(e) => editClick(e, el)}>
                            <Icon type='edit'></Icon>
                          </div>
                        </div>
                      </>}
                  </div>
                )
              })
            }
          </div>
        ))}

      </div>
    </>)
}

export default HintsPage;