import {   
  GETHINTS_SUCCESS,
  GETHINTS_FAIL,
  PUTHINTS_SUCCESS,
  PUTHINTS_FAIL
 } from "../actions/types";

const initialState = {}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETHINTS_FAIL:
      return [...state];
    case GETHINTS_SUCCESS:
      return [...payload.all];
      case PUTHINTS_SUCCESS:
        return [...state];
      case PUTHINTS_FAIL:
        return [...state];
      default:
        return state;
  }
}