import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';
import { socket } from "../../socket";



const getTransactionDetail=(body)=>{
  console.log( authHeader());
  return axios
  .get(constants.URL + "transaction/"+body.id,{ headers: authHeader()})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return Promise.reject(e.response.data);
  });
}

const getTransactionsList=(body)=>{
  const offset = body.offset || 0;
  const limit = body.limit || 10;
  const addPath = body.addPath;

  let filter = `${addPath ? addPath : ''}?offset=${offset}&limit=${limit}`;

  if(body.filter&& Object.keys(body.filter).length ){
    for (let [key, value] of Object.entries(body.filter)) {
      filter+=`&filters[${key}]=${value}`
    }
  }
  if(body.sort&& Object.keys(body.sort).length ){
    for (let [key, value] of Object.entries(body.sort)) {
      filter+=`&sort[${key}]=${value}`
    }
  }
  return axios
  .get(`${constants.URL}transaction${filter}`, { headers: authHeader() })
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return  Promise.reject(e.response.data);
  });
}
const createTransaction = (data) => {
  return axios
    .post(constants.URL + "transaction", data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};


const editTransaction = (body) => {
  return axios
    .put(constants.URL + "transaction/"+body.id, body, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};




const createMessageTransaction = (data) => {
  return axios
    .post(constants.URL + "tech-Transaction/"+data.id, data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};

const returnTransaction = (data) => {
  return axios
    .post(constants.URL + "withdrawal-money", data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};



const transactionFrozen = (data) => {
  return axios
    .post(constants.URL + "transaction/frozen/"+data.id+"/"+data.type, data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};

const transactionFree = (data) => {
  return axios
    .post(constants.URL + "transaction/free/"+data.id+"/"+data.type, data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};



export default {
  getTransactionDetail,
  getTransactionsList,
  createTransaction,
  editTransaction,
  createMessageTransaction,
  transactionFrozen,
  transactionFree,
  returnTransaction
};








