import {
  GETHINTS_SUCCESS,
  GETHINTS_FAIL,
  PUTHINTS_SUCCESS,
  PUTHINTS_FAIL,
  ERROR
} from "./types";

import hintsService from '../services/hints.service'

export const getHints = (form) => (dispatch) => {
    return hintsService.getHints(form).then( (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: GETHINTS_FAIL,
            payload: response.data,
          });
        return response.data;
        }else{
          dispatch({
            type: GETHINTS_SUCCESS,
            payload: response.data,
          });
          return  response.data;
        }
      },
      (error) => {
        dispatch({
          type: GETHINTS_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
  );
}

export const editHints = (form) => (dispatch) => {
  return hintsService.editHints(form).then( (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: PUTHINTS_FAIL,
          payload: response.data,
        });
      return response.data;
      }else{
        dispatch({
          type: PUTHINTS_SUCCESS,
          payload: response.data,
        });
        return  response.data;
      }
    },
    (error) => {
      dispatch({
        type: PUTHINTS_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
    console.log(error);
      return Promise.reject();
    }
);
}