import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getDate from "../../../helpers/getDate";
import { getSupportsList } from "../../../redux/actions/support";

import Filter from "../../../components/Filter/Filter";
import { Icon } from "../../../components/Icon";
import Hint from "../../../components/Hint/Hint";


const SupportListPage =()=>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const support = useSelector((state) => state.support);
    const [ready,setReady]=useState(false);
    const list=useSelector(state=>state?.list)
    const hint=useSelector(state=>state?.hints)
    
    const [isOpen, setIsOpen] = useState(false);
    const [page,setPage]=useState(1);
    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem("supportPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const initFunc=(page)=>{
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.sort={};
        dispatch(getSupportsList(data)).then(res=>{
            setReady(true);
        })
    }
    const createClick = () => {
        navigate(`/supportCreate`);
    };

    useEffect(() => {
        if(!ready){
            initFunc();
        }
        
    }, [ready]);

    const savefilter =()=>{
        localStorage.setItem("supportPage", JSON.stringify(filter));
    }

    const resetFilter=()=>{
        setFilter({});
        initFunc();
    }

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else if (id === "dateFrom" || id === "dateTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }

        setFilter(updatedFilter);
    };

    return(<>
        <div className="profileSection supportListPage" style={{marginBottom:'0px'}}>
            <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
                <h2 >{t('support')}
                    <div className="infoHelp">
                        <Hint align='left' item={hint[43]}/>
                    </div>
                </h2>
                <div className="prFlexRow gameSection" style={{gap:'15px'}}>
                    <button className="prButton prButtonMain" onClick={createClick}>
                            {t("support_create")}
                    </button>
                    <button className="prButton prButtonMain prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                        <Icon type="filter" /> {t("filter_show")}
                    </button>
                </div>
            </div>

            <button className="prButton prButtonMain createRequestMobileButton" onClick={createClick}>
                            {t("support_create")}
            </button>

            <div className="roomList">
                {support?.list?.length>0 &&
                support?.list.map((el,index)=>{
                    return(
                    <div key={index} className="roomItem roomItemSupport prFlexRow">
                        <div className="roomItemLink2" style={{cursor:'pointer'}} onClick={()=>navigate('/supportRoom/'+el.id)}>
                            <div className="roomItemBlockHeader croppedText">{el.id}</div>
                            <div className="roomItemBlockValue croppedText">{el.message.substring(0, 30)}</div>
                        </div>        
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">Статус запиту</div>
                            <div className="roomItemBlockValue">{list[el.status]}</div>
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">Відділ</div>
                            <div className="roomItemBlockValue">{list[el.department]}</div>
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">Час оновлення</div>
                            <div className="prFlexRow dateTimeBlock"> 
                                <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(el.updatedAt?.replace('T',' '),'hh:mm',':')}</div>
                                <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.updatedAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                            </div>
                        </div>
                         <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('time_created')}</div>
                            <div className="prFlexRow dateTimeBlock"> 
                                <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(el.createdAt?.replace('T',' '),'hh:mm',':')}</div>
                                <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.createdAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                            </div>
                        </div>
                    </div>  )

                })
                }
            </div>
            {isOpen && 
                <Filter type='supportPage' resetFilter={resetFilter} accept={initFunc} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen}/>
            }
        </div>
    </>);
}

export default SupportListPage;