import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLogs } from "../../redux/actions/log";

import { Icon } from "../../components/Icon";
import Pagination from "../../components/Pagination/Pagination";
import Hint from "../../components/Hint/Hint";

const mockData = [
  {
    'userId': 'ML02153658745',
    'action': 'successfully_authed',
    'objectType': 'auth',
    'objectId': 'd1f43186-e6b9-4231-b02a-fbb4a9519b3c',
    'date': '18.07.2024, 11:58:17',
  }
]

const LogsJournalPage = () => {
  const [logs, setLogs] = useState([]);
  const [pageCur, setPageCur] = useState(1);
  const [filter, setFilter] = useState({});
  const hint =useSelector(state=>state.hints);
  const [isOpen, setIsOpen] = useState(false);
  const [sort, setSort] = useState('asc')
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initFunc = (page = 1) => {
    let data = {};
    data.limit = 20;
    data.offset = (page - 1) * data.limit;
    data.filter = filter;

    dispatch(getLogs(data)).then(
      (res) => {
        setLogs(res);
      },
      (error) => {
        console.error('Something went wrong', error);
      }
    )
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    const updatedFilter = {};

    updatedFilter[id] = value;
    setFilter({...filter, ...updatedFilter});
  }

  const applyFilter = () => {
    console.log('filter', filter);
    initFunc();
  }

  useEffect(() => {
    initFunc();  
  }, []);

  return (
    <div className="profileSection logsPage" style={{marginBottom:'0px'}}>
      <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
        <h2>
            Журнал дій
            <div className="infoHelp"> <Hint align='left' item={hint[38]}/></div>
        </h2>
      </div>
      <div className="prFlexRow listFilterSection" style={{gap:'15px'}}>
          <div className="prInput">
            <label htmlFor="userId" className="prInputLabel">userId</label>
            <input
              id="userId"
              type="text"
              placeholder="userId"
              className="prInputInput"
              onChange={handleChange}
            />
          </div>
          <div className="prInput">
            <label htmlFor="objectId" className="prInputLabel">objectId</label>
            <input
              id="objectId"
              type="text"
              placeholder="objectId"
              className="prInputInput"
              onChange={handleChange}
            />
          </div>
          <div className="prInput">
            <label htmlFor="type" className="prInputLabel">Тип об'єкту</label>
            <input
              id="type"
              type="text"
              className="prInputInput"
              onChange={handleChange}
            />
          </div>
          <button
            className="prButton prButtonMain prFlexRow"
            onClick={applyFilter}
          >
              Пошук
          </button>
      </div>

      <div className="roomList">
        {
          logs?.all?.length > 0 && logs.all.map((el, index) => {
            return (
              <div key={index} className="roomItem logItem prFlexRow" style={{ flexWrap: 'nowrap', gap: '10px' }}>
                <div className="roomItemTabletBlockHeader prFlexRow">
                  <div className="roomItemLink" style={{ cursor:'pointer' }}>{el.userId}</div>
                  <div className="roomItemBlockValue">{el.date || 'N/A'}</div>
                </div>
                <div className="roomItemBlock" style={{ borderLeft: 'none' }}>
                  <div className="roomItemBlockValue">{el.date || 'N/A'}</div>
                  <div className="roomItemBlockHeader">(дата та час)</div>
                </div>
                <div className="roomItemBlock">
                  <div className="roomItemLink">{el.userId}</div>
                  <div className="roomItemBlockHeader">(userId користувача, що виконав дію)</div>
                </div>
                <div className="roomItemBlock">
                  <div className="roomItemBlockValue">{el.action}</div>
                  <div className="roomItemBlockHeader">(дія)</div>
                </div>
                <div className="roomItemBlock">
                  <div className="roomItemBlockValue">{el.types || 'N/A'}</div>
                  <div className="roomItemBlockHeader">(тип об'єкту)</div>
                </div>
                <div className="roomItemBlock">
                  <div>
                    <div className="roomItemLink visible">{el.objectId || 'N/A'}</div>
                    <div className="roomItemBlockHeader" style={{ marginTop: '7px' }}>(objectId обєкту, що було змінено)</div>
                  </div>
                  <Icon type="infolg"/>
                </div>
              </div>
            )
          }) 
        }
      </div>
      {logs.total>logs.limit && 
        <Pagination
          className="pagination-bar"
          currentPage={pageCur}
          totalCount={logs.total}
          pageSize={logs.limit}
          onPageChange={(page) => {
            setPageCur(page);
            initFunc(page);
          }}
        />}
  </div>
  )
}

export default LogsJournalPage;