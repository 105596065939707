import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getVerification } from "../../../redux/actions/user";
import { useNavigate } from "react-router-dom";
import getDate from "../../../helpers/getDate";

import Pagination from "../../../components/Pagination/Pagination";
import { Icon } from "../../../components/Icon";
import Hint from "../../../components/Hint/Hint";

const VerificationListPage = () => {
  const [list, setList] = useState({});
  const { t } = useTranslation();
  const [ready, setReady] = useState(false);
  const dispatch=useDispatch();
  const [pageCur, setPageCur] = useState(1);
  const dictList = useSelector(state => state.list);
  const hint = useSelector(state => state.hints);
  
  const navigate = useNavigate();

  const initFunc=(page=1)=>{
    let data ={};
    data.limit=20;
    data.offset = (page - 1) * data.limit;
    dispatch(getVerification(data)).then(res=>{
      setList(res)
      console.log(res);
      setReady(true);
    })
  
  }
    useEffect(() => {
        if(!ready){
            initFunc();
        }
            
    }, [ready]);

    const goToProfile = (id) => {
      navigate(`/profile/settings/${id}`);
    }

  return (
    <div className="profileSection supportListPage" style={{marginBottom:'0px'}}>
      <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
        <h2>Запити на верифікацію
          <div className="infoHelp"> <Hint align='left' item={hint[42]}/></div>

        </h2>
      </div>

      <div className="roomList">
          {ready && list?.users?.length>0 &&

            list?.users.map((el, index) => {
              return (
                <div key={index} className="roomItem requestItem prFlexRow verificationRequests" style={{ flexWrap: 'nowrap', gap: '10px' }}>
                  <div className="requestItemCol">
                    <div>
                      <div className="roomItemBlockHeader">ID користувача</div> 
                      <div className="roomItemLink2" style={{cursor:'pointer'}}>
                        <div
                          className="roomItemBlockHeader croppedText"
                          onClick={() => goToProfile(el.userId)}
                        >
                          {el.userId}
                        </div>
                      </div>  
                    </div>
                    <div>
                      <div className="roomItemBlockHeader">Нік користувача</div>
                      <div className="roomItemBlockValue">{el.userVerification.nickname}</div>
                    </div>
                  </div>
                  <div className="requestItemCol">
                    <div>
                      <div className="roomItemBlockHeader">Прізвище</div> 
                      <div className="roomItemBlockValue">{el.lastName}</div>
                    </div>
                    <div>
                      <div className="roomItemBlockHeader">Ім'я та по батькові</div>
                      <div className="roomItemBlockValue">{el.firstName + ' ' + el.secondName}</div>
                    </div>
                  </div>
                  <div className="requestItemCol">
                    <div>
                      <div className="roomItemBlockHeader">Тип документу</div> 
                      <div className="prFlexRow">
                        <div className="roomItemBlockValue">{dictList[el.type]}</div>
                        <Icon type="files"/>
                      </div>
                    </div>
                    <div>
                      <div className="roomItemBlockHeader">Серія та номер</div>
                      <div className="roomItemBlockValue">{el.passportSeries + el.passportNumber}</div>
                    </div>
                  </div>
                  <div className="requestItemCol">
                    <div>
                      <div className="roomItemBlockHeader">Орган, яким видано документ</div> 
                      <div className="roomItemBlockValue">{el.byWhomIssued}</div>
                    </div>
                    <div>
                      <div className="roomItemBlockHeader">Дата видачі</div>
                      <div className="roomItemBlockValue">{el.dateOfIssue}</div>
                    </div>
                  </div>
                  <div className="requestItemCol">
                    <div>
                      <div className="roomItemBlockHeader dateTimeBlock">Час реєстрації</div>
                      <div className="prFlexRow dateTimeBlock" style={{ gap: '18px' }}>
                      <div className="roomItemBlockValue"><Icon type='time'></Icon>{getDate(el.userVerification.createdAt.replace('T',' '),'hh:mm',":")}</div>
                      <div className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.userVerification.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                      </div>
                    </div>
                    <div>
                      <div className="roomItemBlockHeader">Час подачі заявки</div>
                      <div className="prFlexRow dateTimeBlock" style={{ gap: '18px' }}>
                        <div className="roomItemBlockValue"><Icon type='time'></Icon>{getDate(el.createdAt.replace('T',' '),'hh:mm',":")}</div>
                        <div className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }) 
            
          }
          {ready && list.totalFilter>list.limit&& <Pagination
                        className="pagination-bar"
                        currentPage={pageCur}
                        totalCount={list.totalFilter}
                        pageSize={list.limit}
                        onPageChange={(page) => {
                            setPageCur(page);
                            initFunc(page);
                        }}
                    />}
        </div>
    </div>
  )
}


export default VerificationListPage;