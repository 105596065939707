import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';
import { changeLanguage } from '../../i18n';

const Footer = () => {
    const { t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState('');

    useEffect(() => {
        const storedLang = localStorage.getItem('lng');
        if (storedLang) {
            const lang = JSON.parse(storedLang);
            changeLanguage(lang);
            setCurrentLanguage(lang);
        }
    }, []);

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        changeLanguage(selectedLanguage);
        setCurrentLanguage(selectedLanguage); // Update state to reflect new language
        localStorage.setItem('lng', JSON.stringify(selectedLanguage)); // Store the selected language
    };

    return (
        <div className='footerPositition'>
            <div className='footer'>
                <div className='prFlexRow footerTop'>
                    <div className='headerLogo pointer'>
                        <Icon type='logo' />
                        <div className="headerLogoText">
                            <Icon type="rivalsLogoText"/>
                        </div>
                    </div>
                    <div className='prFlexRow footerLinks'>
                        <Icon type='telegram' link='#' />
                        <Icon type='insta' link='#' />
                        <Icon type='fb' link='#' />
                        <Icon type='tikTok' link='#' />
                    </div>
                </div>
                <div className='prFlexRow footerBottom'>
                    <div>
                        {t("right_reserve")}
                    </div>
                    <div className='prFlexRow'>
                        <a href="#">{t("policy_confidential")}</a>
                        <a href="#">{t("rules")}</a>
                        <a href="#">{t("Cookies")}</a>
                        <div>
                            <select 
                                className='langSelect' 
                                id="language-select" 
                                value={currentLanguage} // Use value to control the select
                                onChange={handleLanguageChange}
                            >
                                <option value="ua">{t("Ukrainian")}</option>
                                <option value="en">{t("English")}</option>
                                <option value="ru">{t("Russian")}</option>
                                <option value="de">{t("German")}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
