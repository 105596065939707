import {
    GETTRANSACTIONDETAIL_FAIL,
    GETTRANSACTIONDETAIL_SUCCESS,
    GETTRANSACTIONSLIST_FAIL,
    GETTRANSACTIONSLIST_SUCCESS,
    CREATETRANSACTION_FAIL,
    CREATETRANSACTION_SUCCESS,
    EDITTRANSACTION_SUCCESS,
    EDITTRANSACTION_FAIL,
    ERROR,
    } from "./types";
    
    import transactionService from "../services/transaction.service";
    
      export const getTransactionDetail =(form)=>(dispatch)=>{
          return transactionService.getTransactionDetail(form).then( (response) => {
              if(response.statusText!="OK" || response.data.errors){
                dispatch({
                  type: GETTRANSACTIONDETAIL_FAIL,
                  payload: response.data,
                });
              return response.data;
              }else{
                dispatch({
                  type: GETTRANSACTIONDETAIL_SUCCESS,
                  payload: response.data,
                });
                return  response.data;
              }
            },
            (error) => {
              dispatch({
                type: GETTRANSACTIONDETAIL_FAIL,
              });
              dispatch({
                type: ERROR,
                message:error.message
            });
            console.log(error);
              return Promise.reject();
            }
          );
      }

    export const getTransactionsList=(form)=>(dispatch)=>{
      return transactionService.getTransactionsList(form).then( (response) => {
          if(response.statusText!="OK" || response.data.errors){
            dispatch({
              type: GETTRANSACTIONSLIST_FAIL,
              payload: response.data,
            });
          return response.data;
          }else{
            dispatch({
              type: GETTRANSACTIONSLIST_SUCCESS,
              payload: response.data,
            });
            return  response.data;
          }
        },
        (error) => {
          dispatch({
            type: GETTRANSACTIONSLIST_FAIL,
          });
          dispatch({
            type: ERROR,
            message:error.message
        });
        console.log(error);
          return Promise.reject();
        }
      );
  }

  export const createTransaction=(form)=>(dispatch)=>{
    return transactionService.createTransaction(form).then( (response) => {
          dispatch({
            type: CREATETRANSACTION_SUCCESS,
            payload: response.transaction,
          });
          return  response.transaction;
      },
      (error) => {
        dispatch({
          type: CREATETRANSACTION_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
  }

  export const returnTransaction=(form)=>(dispatch)=>{
    return transactionService.returnTransaction(form).then( (response) => {
          dispatch({
            type: CREATETRANSACTION_SUCCESS,
            payload: response.transaction,
          });
          return  response.transaction;
      },
      (error) => {
        dispatch({
          type: CREATETRANSACTION_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
  }

  

  export const transactionFree=(form)=>(dispatch)=>{
    return transactionService.transactionFree(form).then( (response) => {
          dispatch({
            type: CREATETRANSACTION_SUCCESS,
            payload: response.transaction,
          });
          return  response.transaction;
      },
      (error) => {
        dispatch({
          type: CREATETRANSACTION_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
  }

  export const transactionFrozen=(form)=>(dispatch)=>{
    return transactionService.transactionFrozen(form).then( (response) => {
          dispatch({
            type: CREATETRANSACTION_SUCCESS,
            payload: response.transaction,
          });
          return  response.transaction;
      },
      (error) => {
        dispatch({
          type: CREATETRANSACTION_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
  }



  



  export const editTransaction=(form)=>(dispatch)=>{
    return transactionService.editTransaction(form).then( (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: EDITTRANSACTION_FAIL,
            payload: response.transaction,
          });
          return response.transaction;
        }else{
          dispatch({
            type: EDITTRANSACTION_SUCCESS,
            payload: response.transaction,
          });
          return  response.transaction;
        }
      },
      (error) => {
        dispatch({
          type: EDITTRANSACTION_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
        console.log(error);
        return Promise.reject();
      }
    );
  }
  
  
  
    
  
  
    
    
    