import React from 'react';
import {Route,Routes} from 'react-router-dom';
import Page404 from './pages/404Page/Page404';
import AuthPage from './pages/Authorization/AuthPage/AuthPage';
import RestorePage from './pages/Authorization/RestorePage/RestorePage';
import RegistrationPage from './pages/Authorization/RegistrationPage/RegistrationPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import UsersPage from './pages/UsersPage/UsersPage';
import UserEditPage from './pages/UserEditPage/UserEditPage';
import DirectoryPage from './pages/DirectoryPage/DirectoryPage';
import GamesPage from './pages/GamesPage/GamesPage';
import GameEditPage from './pages/GameEditPage/GameEditPage';
import GamesLibraryPage from './pages/GamesLibraryPage/GamesLibraryPage';
import GamesItemPage from './pages/GamesItemPage/GamesItemPage';
import RoomItemPage from './pages/RoomItemPage/RoomItemPage';
import SupportListPage from './pages/Support/SupportListPage/SupportListPage';
import SupportCreatePage from './pages/Support/SupportCreatePgae/SupportCreatePage';
import SupportItemPage from './pages/Support/SupportItemPage/SupportItemPage';
import AdminReqPage from './pages/AdminNotificationsPages/AdminReqPage/AdminReqPage';
import ArbitrPage from './pages/AdminNotificationsPages/ArbitrPage/ArbitrPage';
import VerificationListPage from './pages/AdminNotificationsPages/VerificationListPage/VerificationListPage';
import VerificationPage from './pages/VerificationPage/VerificationPage';
import AllRoomsPage from './pages/AllRoomsPage/AllRoomsPage';
import TransactionListPage from './pages/TransactionListPage/TransactionListPage';
import LogsJournal from './pages/LogsJournalPage/LogsJournalPage';
import BlacklistPage from './pages/BlacklistPage/BlacklistPage';
import SystemSettingsPage from './pages/SystemSettingsPage/SystemSettingsPage';
import RoomConditionsPage from './pages/RoomConditionsPage/RoomConditionsPage';
import HintsPage from './pages/HintsPage/HintsPage';


const UseRoutes = (props) => {

    return(
        <Routes>
            {!props.isLoggedIn && <Route path='/login'  element={<AuthPage/>}/>}
            {!props.isLoggedIn &&<Route path='/registration'  element={<RegistrationPage/>}/>}
            {!props.isLoggedIn &&<Route path='/restore'  element={<RestorePage/>}/>}
            {!props.isLoggedIn && <Route path="/" element={<AuthPage/>}/>}
            {props.isLoggedIn && <Route path="/" element={<ProfilePage/>}/>}
            {props.isLoggedIn && <Route path="/profile/:type" element={<ProfilePage/>}/>}
            {props.isLoggedIn && <Route path="/profile/:type/:id" element={<ProfilePage/>}/>}
            {/* {auth.isLoggedIn && <Route path="/" element={<StartPage/>}/>} */}
            {props.isLoggedIn && <Route path="/users" element={<UsersPage/>}/>}
            {props.isLoggedIn && <Route path="/userEdit/:id" element={<UserEditPage/>}/>}
            {props.isLoggedIn && <Route path="/directory" element={<DirectoryPage/>}/>}
            {props.isLoggedIn && <Route path="/gamesLibrary" element={<GamesLibraryPage/>}/>}
            {props.isLoggedIn && <Route path="/games" element={<GamesPage/>}/>}
            {props.isLoggedIn && <Route path="/gameEdit/:id" element={<GameEditPage/>}/>}
            {props.isLoggedIn && <Route path="/gameItem/:id" element={<GamesItemPage/>}/>}
            {props.isLoggedIn && <Route path="/room/:id" element={<RoomItemPage/>}/>}
            {props.isLoggedIn && <Route path="/gameItem/:id/:type" element={<GamesItemPage/>}/>}
            {props.isLoggedIn && <Route path="/supportList" element={<SupportListPage/>}/>}
            {props.isLoggedIn && <Route path="/supportCreate" element={<SupportCreatePage/>}/>}
            {props.isLoggedIn && <Route path="/supportListAdmin" element={<SupportListPage/>}/>}
            {props.isLoggedIn && <Route path="/supportRoom/:id" element={<SupportItemPage/>}/>}
            {props.isLoggedIn && <Route path="/conditions" element={<RoomConditionsPage/>}/>}
            
            {props.isLoggedIn && <Route path="/addminreq" element={<AdminReqPage/>}/>}
            {props.isLoggedIn && <Route path="/arbitr" element={<ArbitrPage/>}/>}
            {props.isLoggedIn && <Route path="/verificationlist" element={<VerificationListPage/>}/>}
            {props.isLoggedIn && <Route path="/verification" element={<VerificationPage/>}/>}
            {props.isLoggedIn && <Route path="/logs" element={<LogsJournal/>}/>}
            {props.isLoggedIn && <Route path="/blacklist" element={<BlacklistPage/>}/>}
            {props.isLoggedIn && <Route path="/settings" element={<SystemSettingsPage/>}/>}
            {props.isLoggedIn && <Route path="/hints" element={<HintsPage/>}/>}
            
            {props.isLoggedIn && <Route path="/allRooms" element={<AllRoomsPage/>}/>}
            {props.isLoggedIn && <Route path="/transactionList" element={<TransactionListPage/>}/>}
            
            <Route path='/page404' element={<Page404/>}/>
            <Route path='*' element={<Page404/>}/>


            
        </Routes>
    
    )
}


export default UseRoutes;