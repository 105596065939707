    import { useTranslation } from "react-i18next";
    import React, { useEffect, useState } from "react";  
    import { Icon } from "../Icon";
    import DatePicker from "../DatePicker/DatePicker";
    import { useDispatch, useSelector } from "react-redux";
    import getDate from "../../helpers/getDate";
    import { getUserReputation } from "../../redux/actions/user";
    import TeamResult from "../TeamResult/TeamResult";
    import MultiSelect from "../MultiSelect/MultiSelect";
    import Select from "../Select/Select";
import Hint from "../Hint/Hint";

    const Filter=(props)=>{
        const { t } = useTranslation();
        const [form,setForm]=useState({})
        const [temperary,setTemperary]=useState({})
        const fullList = useSelector((state) => {
            return state.fullList
        });
        const user = useSelector((state) => state.currentUser.user);
        const hint = useSelector((state) => state.hints);
        
        const list = useSelector((state) => {
            return state.list
        });
        const [activeTeamA, setActiveTeamA] = useState('');
        const [activeTeamB, setActiveTeamB] = useState('');
        const dispatch = useDispatch();

        useEffect(() => {
            if(props.type=='leaveRoom'){
                dispatch(getUserReputation({roomId:props.game.id})).then(res=>{
                    console.log(res);
                    setTemperary(res);
                })
            }

            const root = document.querySelector('html')
            root.style.overflow = 'hidden';

            return (() => {
                root.style.overflow = 'auto';
            })
        }, []);
        
    

        const [checkedC, setCheckedC] = useState(false);

        const toggleSidebar = () => {
            props.setIsOpen(!props.isOpen);
        };
    
        const closeSidebar = (e) => {
        if (e.target.classList.contains("overlay")) {
            props.setIsOpen(false);
        }
        };
        const changeHandler = (event) => {
            const {id, value} = event.target || event;

            console.log('event in filter', event);
            console.log('value in filter', id, value);

            setForm({ ...form, [id]: value });
            props.onChange(event);
        };


        useEffect(() => {
            setForm({ ...form, 'checkedC':checkedC });
            if(props.needCheckedC){
                props.onChange({target:{id:'checkedC' , value:checkedC}});
            }
        }, [checkedC]);

        const acceptClick=()=>{
            // props.savefilter();
            props.accept();
            toggleSidebar();
        }
        const acceptClickNoFilter=()=>{
            props.accept();
            toggleSidebar();
        }

        const handleIconClick = (team, resultType) => {
            if (team === 'teamA') {
                setActiveTeamA(resultType);
                if (resultType === 'win') {
                    props.onChange({},{ teamA: '2', teamB: '0' })
                    setActiveTeamB('lose');
                } else if (resultType === 'draft') {
                    props.onChange({},{ teamA: '1', teamB: '1' })
                    setActiveTeamB('draft');
                } else if (resultType === 'lose') {
                    props.onChange({},{ teamA: '0', teamB: '2' })
                    setActiveTeamB('win');
                }
            } else if (team === 'teamB') {
                setActiveTeamB(resultType);
                if (resultType === 'win') {
                    props.onChange({},{ teamA: '0', teamB: '2' })
                    setActiveTeamA('lose');
                } else if (resultType === 'draft') {
                    props.onChange({},{ teamA: '1', teamB: '1' })
                    setActiveTeamA('draft');
                } else if (resultType === 'lose') {
                    props.onChange({},{ teamA: '2', teamB: '0' })
                    setActiveTeamA('win');
                }
            }
        };

        if(props.type=='users'){
            return(
                <>    
                    <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar">
                        <h3 className="prFlexRow">
                            <div className="sidebarFilter">
                                <Icon type='filter'></Icon> 
                                <div>{t('filter_header')}</div>
                            </div> 
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                        <div className="sidebarBody">
                        <div className="prInput">
                            <label htmlFor="all" className={"prInputLabel"}>{t("search_name_nick_enail")}</label>
                            <input onChange={changeHandler} value={props.filter.all||form.all} id="all" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                        </div>
                        <div className="prInput">
                            <label htmlFor="role" className={"prInputLabel"}>{t("role_system")}</label>
                            <Select
                                id="role"
                                value={props.filter.role || ''}
                                onChange={changeHandler}
                                placeholder="Нічого не обрано"
                                options={
                                    fullList.UserRoles.length > 0 &&
                                    fullList.UserRoles
                                }
                            />
                        </div>
                        <div className="prInputPeriod">
                            <label htmlFor="birthday_period" className={"prInputLabel"}>{t("birthday_period")}</label>
                            <div className="prFlexRow">
                                <DatePicker onChange={changeHandler} id="dataBirthdayFrom" value={(props.filter.dataBirthdayFrom && getDate(props.filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''} icon={true}></DatePicker>
                                <div className="prInputPeriodDevider">-</div>
                                <DatePicker onChange={changeHandler} id="dataBirthdayTo" value={(props.filter.dataBirthdayTo && getDate(props.filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''}icon={true}></DatePicker>
                            </div>
                        </div>
            
                        <div  className="prInput">
                            <label htmlFor="sex" className={"prInputLabel"}>{t("gender")}</label>
                            <Select
                                id="sex"
                                value={props.filter.sex || ''}
                                onChange={changeHandler}
                                placeholder="Нічого не обрано"
                                options={
                                    fullList.Sex.length > 0 &&
                                    fullList.Sex
                                }
                            />
                        </div>
                        <div  className="prInput">
                            <label htmlFor="game" className={"prInputLabel"}>{t("game")}</label>
                            <select disabled className={"prInputInput"} name="" id="">
                                <option value="">{t('empty_select')}</option>
                                <option value="">CS</option>
                                <option value="">Dota</option>
                                <option value="">WoT</option>
                            </select>
                        </div>
                        <div className="prInputPeriod">
                            <label htmlFor="reputationFrom" className={"prInputLabel"}>{t("reputation")}</label>
                            <div className="prFlexRow">
                                <select 
                                    className={"prInputInput"}  id="reputationFrom"
                                    value={props.filter?.reputationFrom || ""}
                                    onChange={changeHandler}
                                >
                                    <option value=""></option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                                <div className="prInputPeriodDevider">-</div>
                                <select className={"prInputInput"}  id="reputationTo"
                                                value={props.filter?.reputationTo || ""}
                                                onChange={changeHandler}
                                                >
                                    <option value=""></option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                        </div>
                        <div  className="prInput">
                            <label htmlFor="status" className={"prInputLabel"}>{t("status")}</label>
                            <select disabled className={"prInputInput"} name="" id="">
                                <option value="">{t('empty_select')}</option>
                                <option value="">Верифіковано</option>
                                <option value="">Очікує</option>
                                <option value="">Не верифіковано</option>
                            </select>
                        </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                            </div>
                            <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                        </div>
                    </div>
                    </div>    
                </>)
        }
        else if (props.type=='rooms'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar">
                        <h3 className="prFlexRow">
                            <div className="sidebarFilter">
                                <Icon type='filter'></Icon> 
                                <div>{t('filter_header')}</div>
                            </div> 
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                        <div className="sidebarBody">
                        <div className="prInput">
                            <label htmlFor="roomId" className={"prInputLabel"}>{t("search_room_id")}</label>
                            <input onChange={changeHandler} value={props.filter?.roomId||''} id="roomId" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                        </div>
                        <div className="prInputPeriod">
                            <label htmlFor="costRate" className={"prInputLabel"}>{t("cost_rate")}</label>
                            <div className="prFlexRow">
                            <input onChange={changeHandler} id="priceFrom" value={props.filter?.priceFrom||''}  type="text" className={"prInputInput"} placeholder={'0$'} />
                            <div className="prInputPeriodDevider">-</div>
                            <input onChange={changeHandler} id="priceTo" value={props.filter?.priceTo||''}  type="text" className={"prInputInput"} placeholder={'10000$'} />
                            </div>
                        </div>
                        {/* <div className="prInput">
                            <label htmlFor="tockenType" className={"prInputLabel"}>{t("tocken_type")}</label>
                                    <select
                                    className={ "prInputInput"}
                                        id="tockenType"
                                        value={props.filter.tockenType || ""}
                                        onChange={changeHandler}
                                    >
                                    <option value="">{t('empty_select')}</option>
                                    {fullList.TypeToken.length > 0 &&
                                        fullList.TypeToken.map(el => (
                                        <option key={el.id} value={el.id}>{el.title}</option>
                                    ))
                                    }
                                
                                </select>
                        </div> */}
                        <div className="prInput">
                            <label htmlFor="role" className={"prInputLabel"}>{t("role_room")}</label>
                                    <select
                                    className={ "prInputInput"}
                                        id="role"
                                        value={props.filter.role || ""}
                                        onChange={changeHandler}
                                    >
                                    <option value="">{t('empty_select')}</option>
                                    {fullList.UserStatus.length > 0 &&
                                        fullList.UserStatus.map(el => (
                                        <option key={el.id} value={el.id}>{el.title}</option>
                                    ))
                                    }
                                </select>
                        </div>
                        <div className="prInput">
                            <label htmlFor="gameId" className={"prInputLabel"}>{t("game")}</label>
                                    <select
                                    className={ "prInputInput"}
                                        id="gameId"
                                        value={props.filter.gameId || ""}
                                        onChange={changeHandler}
                                    >
                                    <option value="">{t('empty_select')}</option>
                                    {user.gamesNickname.map(el=>{
                                        return(
                                            <option value={el.gamePlay?.id}>{el.gamePlay?.name}</option>
                                        )
                                        
                                    })}
                                </select>
                        </div>
                        <MultiSelect
                            placeholder="Оберіть формат"
                            label="Формат"
                            options={fullList.Formats}
                            id="formats"
                            value={props.filter.formats}
                            onChange={changeHandler}
                        />
                        <div className="prInput">
                            <label htmlFor="platform" className={"prInputLabel"}>{t("platform")}</label>
                                    <select
                                    className={ "prInputInput"}
                                        id="platform"
                                        value={props.filter.platform || ""}
                                        onChange={changeHandler}
                                    >
                                    <option value="">{t('empty_select')}</option>
                                    {fullList.Platform.length > 0 &&
                                        fullList.Platform.map(el => (
                                        <option key={el.id} value={el.id}>{el.title}</option>
                                    ))
                                    }
                                </select>
                        </div>
                        <div className="prInput">
                            <label htmlFor="result" className={"prInputLabel"}>{t("result")}</label>
                                    <select
                                    className={ "prInputInput"}
                                        id="result"
                                        value={props.filter.result || ""}
                                        onChange={changeHandler}
                                    >
                                    <option value="">{t('empty_select')}</option>
                                    <option key={"0"} value={"0"}>Поразка</option>
                                    <option key={"1"} value={"1"}>Нічия</option>
                                    <option key={"2"} value={"2"}>Перемога</option>
                                </select>
                                
                        </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                            </div>
                            <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                        </div>
                    </div>
                </div>    
            </>)
        }
        else if (props.type=='roomsFilter'){
            console.log(props);
            return(
        <>
                        <div className="overlay" onClick={closeSidebar}>
                        <div className="sidebar">
                            <h3 className="prFlexRow">
                                <div className="sidebarFilter">
                                    <Icon type='filter'></Icon> 
                                    <div>{t('filter_header')}</div>
                                </div> 
                                <div className="prFlexRow sidebarFilterButtons"> 
                                    <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                    <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                </div>
                            </h3>
                            <div className="sidebarBody">
                            <div className="prInput">
                                <label htmlFor="roomId" className={"prInputLabel"}>{t("search_room_id")}</label>
                                <input onChange={changeHandler} value={props.filter?.roomId||''} id="roomId" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                            </div>
                            <div className="prInputPeriod">
                                <label htmlFor="costRate" className={"prInputLabel"}>{t("cost_rate")}</label>
                                <div className="prFlexRow">
                                <input onChange={changeHandler} id="priceFrom" value={props.filter?.priceFrom||''}  type="text" className={"prInputInput"} placeholder={'0$'} />
                                <div className="prInputPeriodDevider">-</div>
                                <input onChange={changeHandler} id="priceTo" value={props.filter?.priceTo||''}  type="text" className={"prInputInput"} placeholder={'10000$'} />
                                </div>
                            </div>

                            <div className="prInput">
                                <label htmlFor="platform" className={"prInputLabel"}>{t("platform")}</label>
                                        <select
                                        className={ "prInputInput"}
                                            id="platform"
                                            value={props.filter.platform || ""}
                                            onChange={changeHandler}
                                        >
                                        <option value="">{t('empty_select')}</option>
                                        {props.game.platforms.length > 0 &&
                                            props.game.platforms.map(el => (
                                            <option key={el} value={el}>{list[el]}</option>
                                        ))
                                        }
                                    </select>
                            </div>
                            <div className="prInput">
                                <label htmlFor="format" className={"prInputLabel"}>{t("format")}</label>
                                        <select
                                        className={ "prInputInput"}
                                            id="format"
                                            value={props.filter.format || ""}
                                            onChange={changeHandler}
                                        >
                                        <option value="">{t('empty_select')}</option>
                                        {props.game.formats.length > 0 &&
                                            props.game.formats.map(el => (
                                            <option key={el} value={el}>{el +'/'+el}</option>
                                        ))
                                        }
                                    </select>
                            </div>

                                <div className="userEditButtons">
                                    <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                    <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                                </div>
                                <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                            </div>
                        </div>
                        </div>    
                    </>)
        }
        else if (props.type=='transaction'){
            return(
        <>
                        <div className="overlay" onClick={closeSidebar}>
                        <div className="sidebar">
                            <h3 className="prFlexRow">
                                <div className="sidebarFilter">
                                    <Icon type='filter'></Icon> 
                                    <div>{t('filter_header')}</div>
                                </div> 
                                <div className="prFlexRow sidebarFilterButtons"> 
                                    <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                    <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                </div>
                            </h3>
                            <div className="sidebarBody">
                            <div className="prInput">
                                <label htmlFor="id" className={"prInputLabel"}>{t("search_id_transaction")}</label>
                                <input onChange={changeHandler} id="id" type="text" value={props.filter.id||''} className={"prInputInput"} placeholder={t("enter_value")} />
                            </div>
                            <div className="prInputPeriod">
                                <label htmlFor="costRate" className={"prInputLabel"}>{t("cost_rate")}</label>
                                <div className="prFlexRow">
                                <input onChange={changeHandler} id="quantityFrom" type="text" value={props.filter.quantityFrom||''} className={"prInputInput"} placeholder={'0$'} />
                                <div className="prInputPeriodDevider">-</div>
                                <input onChange={changeHandler} id="quantityTo" value={props.filter.quantityTo||''} type="text" className={"prInputInput"} placeholder={'10000$'} />
                                </div>
                            </div>
                            <div className="prInput">
                                <label htmlFor="type" className={"prInputLabel"}>{t("transaction_type")}</label>
                                        <select
                                        className={ "prInputInput"}
                                            id="type"
                                            value={props.filter.type || ""}
                                            onChange={changeHandler}
                                        >
                                        <option value="">{t('empty_select')}</option>
                                        <option value={'1'}>Поповнення</option>
                                        <option value={'0'}>Вивід</option>
                                    </select>
                            </div>
                            <div className="prInput">
                                <label htmlFor="paymentSystem" className={"prInputLabel"}>{t("payment_org")}</label>
                                        <select
                                        className={ "prInputInput"}
                                            id="paymentSystem"
                                            value={props.filter.paymentSystem || ""}
                                            onChange={changeHandler}
                                        >
                                        <option value="">{t('empty_select')}</option>
                                        <option value={'merchant-api.sticklab'}>merchant-api.sticklab</option>
                                    </select>
                            </div>
                            <div className="prInput">
                                <label htmlFor="transactionStatus" className={"prInputLabel"}>{t("transaction_status")}</label>
                                        <select
                                        className={ "prInputInput"}
                                            id="transactionStatus"
                                            value={props.filter.transactionStatus || ""}
                                            onChange={changeHandler}
                                        >
                                            <option value="">{t('empty_select')}</option>
                                            {fullList.TransactionStatus.map(el=>{
                                                return(
                                                    <option value={el.id}>{el.title}</option>
                                                )
                                            })}
                                    </select>
                            </div>
                            {/* <div className="prInput">
                                <label htmlFor="tockenType" className={"prInputLabel"}>{t("tocken_type")}</label>
                                    <select
                                        className={ "prInputInput"}
                                            id="tockenType"
                                            value={props.filter.tockenType || ""}
                                            onChange={changeHandler}
                                        >
                                        <option value="">{t('empty_select')}</option>
                                        <option value={'fiat'}>Fiat tocken</option>
                                        <option value={'crypto'}>Crypto tocken</option>
                                    </select>
                            </div> */}
                                <div className="userEditButtons">
                                    <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                    <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                                </div>
                                <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                            </div>
                        </div>
                        </div>    
                    </>)
        }
        else if (props.type=='createRooms'){
                return(
            <>
                            <div className="overlay" onClick={closeSidebar}>
                            <div className="sidebar bigSidebar">
                                <h3 className="prFlexRow">
                                    <h2>{t('message')}
                                    <div className="infoHelp">
                                        <Hint align='center' item={hint[47]}/>
                                    </div>                                    </h2>
                                    <div className="prFlexRow sidebarFilterButtons"> 
                                        <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                    </div>
                                </h3>

                                <div className="simpleText">
                                    Якщо ви створюєте кімнату вперше, прочитайте наші рекомендації
                                    та поради у розділі “<a href="#">Створення ігрової кімнати</a>”
                                </div>
                                <div className="sidebarBody">
                                {props.game?
                                <div className="prInput">
                                <label htmlFor="game" className={"prInputLabel"}>{t("choose_game")}</label>
                                        <select
                                        className={ "prInputInput"}
                                            id="game"
                                            value={props.filter.game || ""}
                                            onChange={changeHandler}
                                            disabled={true}
                                        >
                                        <option value={props.game.id}>{props.game.name}</option>
                                    </select>
                            </div>
                                :<div className="prInput">
                                    <label htmlFor="game" className={"prInputLabel"}>{t("choose_game")}</label>
                                            <select
                                                className={ "prInputInput"}
                                                id="selectedGame"
                                                value={props.filter.selectedGame || ""}
                                                onChange={changeHandler}
                                            >
                                            <option value="">{t('empty_select')}</option>
                                            {user.gamesNickname.map(el=>{
                                                return(
                                                <option value={el.gameId}>{el.gamePlay.name}</option>
                                                )
                                            })}
                                        </select>
                                </div>}

                                {/* <div className="prInput">
                                    <label htmlFor="isPrivateRoom" className={"prInputLabel"}>{t("is_private_room")}</label>
                                    <div className="">
                                        <div className="">Так</div>
                                        <div className="">Ні</div>
                                    </div>
                                </div> */}


                                <div className="prInput">
                                    <label htmlFor="cost_invite" className={"prInputLabel"}>{t("cost_invite")}</label>
                                    <div className="prFlexRow">
                                        <input onChange={changeHandler} style={{width:'88px'}} id="price" type="text" value={props.filter.price||''} className={"prInputInput"} placeholder={''} />
                                        <div className="checkInput prFlexRow" style={{ width: '60px',marginLeft:"55px"}}>
                                            <div onClick={() => setCheckedC(false)} className={!checkedC ? "prCheckBox checked" : "prCheckBox"} >
                                            </div>
                                            <div style={{ textAlign: 'left' }} onClick={() => setCheckedC(false)}>
                                                <Icon type='fTocken'></Icon>
                                            </div>
                                            </div>
                                        <div className="checkInput prFlexRow" style={{ width: '60px',marginLeft:"25px"}}>
                                            <div onClick={() => setCheckedC(true)} className={checkedC ? "prCheckBox checked" : "prCheckBox"} >
                                            </div>
                                            <div style={{ textAlign: 'left' }} onClick={() => setCheckedC(true)}>
                                                <Icon type='cTocken'></Icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="prInput">
                                        <div className="prInput" style={{ marginBottom: "-9px" }}>
                                            <label htmlFor="platform" className={"prInputLabel"}>
                                            {t("platform")}
                                            </label>
                                        </div>
                                        {fullList.Platform.map((el) => {
                                            if(props.game && props.game['platforms']?.includes(el.id)){
                                                return(
                                                <div className="checkInput prFlexRow" key={el.id} style={{ width: "180px", marginTop: "9px" }}>
                                                    <div
                                                        id={el.id}
                                                        onClick={props.selectClick}
                                                        className={props.filter['platform']?.includes(el.id) ? "prCheckBox checked" : "prCheckBox"}
                                                    ></div>
                                                    <div style={{ textAlign: "left", width: "180px" }} id={el.id} onClick={props.selectClick}>
                                                        {t(el.title)}
                                                    </div>
                                                </div>
                                                )
                                            }else if(!props.game){
                                                return user.gamesNickname.map(element=>{
                                                    if(element.gamePlay.id === props.filter.selectedGame &&element.gamePlay.platforms.includes(el.id)){
                                                        return(
                                                            <div className="checkInput prFlexRow" key={el.id} style={{ width: "180px", marginTop: "9px" }}>
                                                                <div
                                                                    id={el.id}
                                                                    onClick={props.selectClick}
                                                                    className={props.filter['platform']?.includes(el.id) ? "prCheckBox checked" : "prCheckBox"}
                                                                ></div>
                                                                <div style={{ textAlign: "left", width: "180px" }} id={el.id} onClick={props.selectClick}>
                                                                    {t(el.title)}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        })}
                                </div >
                                <div className="prInput">
                                    <div className="prInput" style={{ marginBottom: "-9px" }}>
                                        <label htmlFor="format" className="prInputLabel">{t("platform")} для вашого пристрою</label>
                                        <select
                                            className="prInputInput"
                                            id="platformId"
                                            value={props.filter.platformId || ""}
                                            onChange={changeHandler}
                                        >
                                            <option value="">{t('empty_select')}</option>
                                            {user.gamesNickname.map((el) => {
                                                if ((props.game &&  el.gameId === props.game.id) ||(el.gameId === props.filter.selectedGame) ) {
                                                    return el.platforms.map((elem, index) => {
                                                        if (props.filter['platform']?.includes(elem.platform)) {
                                                            return (
                                                                <option key={index} value={elem.platform}>
                                                                    {list[elem.platform]}
                                                                </option>
                                                            );
                                                        }
                                                        return null;
                                                    });
                                                }
                                                return null;
                                            })}
                                        </select>
                                    </div>
                                </div>
                                    
                                <div className="prInput">

                                    <label htmlFor="format" className={"prInputLabel"}>{t("format")}</label>
                                            <select
                                            className={ "prInputInput"}
                                                id="format"
                                                value={props.filter.format || ""}
                                                onChange={changeHandler}
                                            >
                                            <option value="">{t('empty_select')}</option>
                                            {props?.game ? props?.game?.formats?.length > 0 &&
                                                props?.game?.formats?.map(el => (
                                                <option key={el} value={el}>{el+' x '+el}</option>
                                            ))
                                            :
                                            user.gamesNickname.map(elem=>{
                                                if(elem.gameId==props.filter.selectedGame){
                                                    return(
                                                        elem.gamePlay.formats.map(el=>{
                                                            return(
                                                                <option key={el} value={el}>{el+' x '+el}</option>
                                                            )
                                                        })
                                                    )
                                                
                                                    
                                                }
                                            })
                                            }
                                        </select>
                                </div>

                                <div className="prInput">
                                    <label htmlFor="description" className={"prInputLabel"}>{t("description")}</label>
                                    <div className="simpleText">
                                    Якщо у вас є певні умови, наприклад на якому сервері або за якими правилами будете грати, обов’язково напишіть про це в описі, щоб інші учасники орієнтувались
                                    </div>
                                    <textarea onChange={changeHandler} id="description" value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                                </div>
                                <div className="userEditButtons">
                                    <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                                    <button className="prButton prButtonMain" disabled={!props.filter?.format?.length>0 || !props.filter?.platformId?.length>0 || !props.filter?.price?.length>0} onClick={acceptClickNoFilter}>{t('next')}</button>
                                </div>
                                </div>
                            </div>
                            </div>    
                        </>)
        }
        else if (props.type=='addMyGame'){
                    return(
                <>
                                <div className="overlay" onClick={closeSidebar}>
                                <div className="sidebar myGameEdit">
                                    <h3 className="prFlexRow">
                                        <h2 className="chooseFor">
                                            {t('choose_options_for_game')} 
                                            <br />
                                            <span className="chooseForGame"> {props.filter.name}!</span>
                                        </h2>
                                        <div className="prFlexRow sidebarFilterButtons" style={{alignSelf:'baseline'}}> 
                                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                        </div>
                                    </h3>

                                    <div className="platform">
                                        <div className="prInput" style={{ marginBottom: "-9px" }}>
                                            <label htmlFor="platform" className={"prInputLabel"}>
                                            {t("platform")}
                                            <div className="infoHelp">
                                                <Hint align='right' item={hint[44]}/>
                                            </div>
                                            </label>
                                        </div>
                                        {fullList.Platform.map((el) => {
                                            if(props.filter['platforms']?.includes(el.id)){
                                                return(
                                                <div className="checkInput prFlexRow" key={el.id} style={{ width: "180px", marginTop: "9px" }}>
                                                    <div
                                                        id={el.id}
                                                        onClick={props.selectClick}
                                                        className={props.filter['platform']?.includes(el.id) ? "prCheckBox checked" : "prCheckBox"}
                                                    ></div>
                                                    <div style={{ textAlign: "left", width: "180px" }} id={el.id} onClick={props.selectClick}>
                                                        {t(el.title)}
                                                    </div>
                                                </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className="platform">
                                        {props?.filter?.platform.map(el=>{
                                            return(
                                                <div className="prInput">
                                                    <label htmlFor={el} className={"prInputLabel"}>{t("nickname_for")} {list[el]}
                                                    <div className="infoHelp">
                                                        <Hint align='right' item={hint[45]}/>
                                                    </div>
                                                    </label>
                                                    <input onChange={changeHandler} id={el} type="text" value={props?.filter?.platformNew ? props?.filter?.platformNew[el]:''} className={"prInputInput"} placeholder={t("enter_value")} />
                                                </div>
                                            )

                                        })}

                                    </div>
                                
                                    <div className="userEditButtons">
                                        <button
                                            className="prButton prButtonSecond"
                                            disabled={props?.filter?.platform?.length<1}
                                            onClick={props.accept}
                                            style={{ margin: '0 10px' }}
                                        >
                                            Зберегти
                                        </button>
                                    </div>
                                    </div>
                                </div>    
                            </>)
        }
        else if (props.type=='joinRoom'){
            return(
                <>
                    <div className="overlay" onClick={closeSidebar}>
                        <div className="sidebar bigSidebar">
                        <h3 className="prFlexRow">
                            <h2 className="chooseFor">
                                {t('choose_options_for_game')} 
                                <br />
                                <span className="chooseForGame"> {props.game.game.name}!</span>
                            </h2>
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                    <div className="sidebarBody">

                    <div className="prInput" style={{ marginBottom: "20px" }}>
                        <label htmlFor="format" className="prInputLabel">{t("platform")} для вашого пристрою</label>
                        <select
                            className="prInputInput"
                            id="platformId"
                            value={props.filter.platformId || ""}
                            onChange={changeHandler}
                        >
                            <option value="">{t('empty_select')}</option>
                            {user.gamesNickname.map((el) => {
                                if (props.game && el.gameId === props.game.game.id) {
                                    return el.platforms.map((elem, index) => {
                                        if (props.game['platform']?.includes(elem.platform)) {
                                            return (
                                                <option key={index} value={elem.platform}>
                                                    {list[elem.platform]}
                                                </option>
                                            );
                                        }
                                        return null;
                                    });
                                }
                                else if (!props.game && el.gameId === props.filter.game) {
                                    return el.platforms.map((elem, index) => {
                                        if (el.gamePlay['platforms']?.includes(elem.platform)) {
                                            return (
                                                <option key={index} value={elem.platform}>
                                                    {list[elem.platform]}
                                                </option>
                                            );
                                        }
                                        return null;
                                    });
                                }
                                return null;
                            })}
                        </select>
                    </div>
                    <div className="userEditButtons">
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                            <button className="prButton prButtonMain" disabled={!props.filter.platformId} onClick={acceptClickNoFilter}>{t('next')}</button>
                        </div>
                        </div>
                    </div>    
                    </div>    
                </>)
        }
        else if (props.type=='leaveRoom'){
            return(
                <>
                    <div className="overlay" onClick={closeSidebar}>
                        <div className="sidebar bigSidebar">
                        <h3 className="prFlexRow">
                            <h2 className="">
                                Ви точно хочете покинути кімнату? 
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[55]}/>
                                </div>
                                {/* <span className="chooseForGame"> {props.game.game.name}!</span> */}
                            </h2>
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                    <div className="sidebarBody">
                        <div className="attentionPart dFlex">
                            <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                            <div>
                                <h3>Увага!</h3>
                                <div>Нагадуємо що:</div>
                                <div>За 3 виходи з Кімнати поспіль з вашої репутації буде списано 1 бал</div>
                                <div>За 5 виходів з Кімнати впродовж доби з вашої репутації буде списано 2 бали</div>
                            </div>
                        </div>

                        <div className="leavingRoomText">
                            <h3>Зараз у вас:</h3>
                            <div> {temperary?.consecutiveExits} із 3 виходів із кімнати поспіль</div>
                            <div> {temperary?.dayExit} із 5 виходи із кімнати впродовж доби</div>
                        </div>

                    <div className="userEditButtons">
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                            <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>{t('next')}</button>
                        </div>
                        </div>
                    </div>    
                    </div>    
                </>)
        }
        else if (props.type=='callAdmin'){
            return(
                <>
                    <div className="overlay" onClick={closeSidebar}>
                        <div className="sidebar bigSidebar">
                        <h3 className="prFlexRow">
                            <h2 className="">
                                Форма виклику адміністратора
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[54]}/>
                                </div>
                                {/* <span className="chooseForGame"> {props.game.game.name}!</span> */}
                            </h2>
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                    <div className="sidebarBody">
                        <div className="prInput">
                            <div className="simpleText">
                            Опишіть, будь ласка, суть своєї скарги
                            </div>
                            <textarea onChange={changeHandler} id="description" maxLength={250} value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                            <div className="prInputLength">{props.filter?.description?.length || 0}/250</div>
                        </div>
                        <div className="attentionPart dFlex">
                            <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                            <div>
                                <h3>Увага!</h3>
                                <div>Безпричинний виклик адміністратора може призвести до зняття репутаційних балів</div>
                            </div>
                        </div>
                            <div className="userEditButtons" style={{marginTop:'40px'}}>
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                            <button className="prButton prButtonMain" disabled={props.filter?.description?.length<1} onClick={acceptClickNoFilter}>Поскаржитись</button>
                        </div>
                        </div>
                    </div>    
                    </div>    
                </>)
        }
        else if(props.type=='callRef'){
            return(
                <> 
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                    <h3 className="prFlexRow">
                        <h2 className="">
                            Завантажити файли із доказами?
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[48]}/>
                                </div>
                        </h2>
                        <div className="prFlexRow sidebarFilterButtons"> 
                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                        </div>
                    </h3>
                <div className="sidebarBody">
                    <div className="prInput">
                        <div className="simpleText">
                            Виклик арбітра потрібен лише тоді, коли один із учасників не погоджується із результатами, або підозрює іншого учасника в неспортивній поведінці (шахрайство, читерство тощо).  Цю дію неможливо скасувати чи відредагувати, тому будьте уважні, заповнюючи цю форму
                        </div>
                    </div>

                    <div className="prInput">
                            <label className="prInputLabel">
                                Опишіть причину виклику арбітра
                            </label>
                            <div className="simpleText">
                                Опишіть, будь ласка, суть своєї скарги
                            </div>
                            <textarea onChange={changeHandler} id="description" value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                            <div className="prInputLength">{props.filter?.description?.length || 0}/250</div>
                    </div>

                    <div>
                        <TeamResult handleIconClick={handleIconClick} activeTeamA={activeTeamA} activeTeamB={activeTeamB}></TeamResult>
                    </div>

                    <div className="switch-container prInput" style={{marginTop:'40px'}}>
                        <label className="prInputLabel">Чи користувались Ви під час гри клієнтом?
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[50]}/>
                                </div>
                        </label>
                        <label className="switch">
                            <input
                            type="checkbox"
                            id="isClientUsed"
                            checked={props.filter.isClientUsed}
                            onChange={changeHandler}
                            />
                            <span className="slider round"></span>
                            {props.filter.isClientUsed==1?<span className="switchText left">Так</span>:
                            <span className="switchText right">Ні</span>}
                        </label>
                    </div>

                    
                    <div className="prInput">
                        <div className="simpleText" style={{marginBottom:'11px'}}>
                            Якщо хочете надати нам більше інформації, можете розмістити її на Dropbox чи Google Drive, відкрити доступ до перегляду, і надіслати нам посилання
                        </div>
                        <input onChange={changeHandler} value={props.filter.link||form.link} id="link" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                    </div>
                    <div className="attentionPart dFlex">
                        <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                        <div>
                            <h3>Увага!</h3>
                            <div>Безпричинний виклик арбітра може призвести до зняття репутаційних балів</div>
                        </div>
                    </div>
                    <div className="userEditButtons" style={{marginTop:'40px'}}>
                        <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                        <button className="prButton prButtonMain" disabled={props.filter?.description?.length<1} onClick={acceptClickNoFilter}>Надіслати</button>
                    </div>
                    </div>
                </div>    
                </div>   

                </>
            )
        }
        else if(props.type=='callRefAdd'){
            return(
                <> 
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                    <h3 className="prFlexRow">
                        <h2 className="">
                            Завантажити файли із доказами?
                            <div className="infoHelp">
                                <Hint align='center' item={hint[51]}/>
                            </div>
                        </h2>
                        <div className="prFlexRow sidebarFilterButtons"> 
                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                        </div>
                    </h3>
                <div className="sidebarBody">
                    <div className="switch-container prInput">
                        <label className="prInputLabel">Чи користувались Ви під час гри клієнтом?
                            <div className="infoHelp">
                                <Hint align='center' item={hint[52]}/>
                            </div>
                        </label>
                        <label className="switch">
                            <input
                            type="checkbox"
                            id="isClientUsed"
                            checked={props.filter.isClientUsed==1}
                            onChange={changeHandler}
                            />
                            <span className="slider round"></span>
                            {props.filter.isClientUsed==1?<span className="switchText left">Так</span>:
                            <span className="switchText right">Ні</span>}
                        </label>
                    </div>
                    <div className="prInput">
                        <div className="simpleText" style={{marginBottom:'11px'}}>
                            Якщо хочете надати нам більше інформації, можете розмістити її на Dropbox чи Google Drive, відкрити доступ до перегляду, і надіслати нам посилання
                        </div>
                        <input onChange={changeHandler} value={props.filter.link||form.link} id="link" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                    </div>

                    <div className="prInput">
                        <label className="prInputLabel">Ваш суперник до виклику арбітра додав наступний текст:</label>
                        <div className="infoWithPrev">
                            {props.game.appealArbitrator[0].description?.split("\n").map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </div>
                    </div>
                    <div className="prInput">
                            <label className="prInputLabel">
                                Напишіть свою відповідь (за бажання)
                            </label>
                            <div className="simpleText">
                                Зерніть увагу, Ваш суперник буде бачити текст, написаний к цьому полі, тому радимо бути уважними та обережними у формулюваннях
                            </div>
                            <textarea onChange={changeHandler} id="description" value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                            <div className="prInputLength">{props.filter?.description?.length || 0}/250</div>
                    </div>
                    <div className="attentionPart dFlex">
                        <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                        <div>
                            <h3>Увага!</h3>
                            <div>Нагадуємо, що підробка доказів та введення в оману може призвети до втрати балів репутації та навіть бану</div>
                        </div>
                    </div>
                    <div className="userEditButtons" style={{marginTop:'40px'}}>
                        <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                        <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>Надіслати</button>
                    </div>
                    </div>
                </div>    
                </div>   

                </>
            )
        }
        else if(props.type=='inviteUser'){
            return(
                <> 
                    <div className="overlay" onClick={closeSidebar}>
                        <div className="sidebar bigSidebar">
                            <h3 className="prFlexRow">
                                <h2 className="">
                                Запросити гравця до кімнати
                                    <div className="infoHelp">
                                        <Hint align='center' item={hint[53]}/>
                                    </div>
                                </h2>
                                <div className="prFlexRow sidebarFilterButtons"> 
                                    <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                </div>
                            </h3>
                        
                            <div className="sidebarBody">
                                <div className="prInput">
                                    <label htmlFor="roomId" className={"prInputLabel"}>Ви можете знайти гравця за нікнеймом, яким він підписаний саме в цій грі</label>
                                    <input onChange={changeHandler} value={form.nickname||''} id="nickname" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                                </div>

                                <div>
                                {props.users?.length > 0 &&
                                    props.users.map(el => {
                                        const isSelected = props.userSet.includes(el.nicknameUser.id); // Проверяем, выбран ли пользователь
                                        return (
                                            <div
                                                className={`userItemFilter ${isSelected ? "selected" : ""}`}
                                                key={el.id}
                                                id={el.nicknameUser.id}
                                                onClick={() => {
                                                    if (isSelected) {
                                                        props.setUserSet(props.userSet.filter(userId => userId !== el.nicknameUser.id));
                                                    } else {
                                                        props.setUserSet([...props.userSet, el.nicknameUser.id]);
                                                    }
                                                }}
                                            >   <div className="userItemFilterAvatar">
                                                    <Icon type="avatar" />
                                                </div>
                                                <div>
                                                    {el.nickname} 
                                                </div>
                                                {el.platform=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                                    <Icon type='pc'></Icon>
                                                }
                                                {el.platform=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                                <Icon type='ps'></Icon>
                                                }
                                                {el.platform=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                                <Icon type='xbox'></Icon>
                                                }
                                            </div>
                                        );
                                    })}
                                </div>

                            <div className="userEditButtons" style={{marginTop:'40px'}}>
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>Запросити</button>
                            </div>
                            </div>
                    </div>    
                    </div>   
                </>
            )
        }
        else if (props.type === 'supportPage') {
            return(
                <> 
                    <div className="overlay" onClick={closeSidebar}>
                        <div className="sidebar">
                            <h3 className="prFlexRow">
                                <div className="sidebarFilter">
                                    <Icon type='filter'></Icon> 
                                    <div>{t('filter_header')}</div>
                                </div> 
                                <div className="prFlexRow sidebarFilterButtons"> 
                                    <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                    <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                </div>
                            </h3>
                            <div className="sidebarBody">
                                <div className="prInput">
                                    <label htmlFor="id" className={"prInputLabel"}>Пошук за ID запиту</label>
                                    <input onChange={changeHandler} value={form.id || ''} id="id" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                                </div>
                                <div className="prInput">
                                    <label htmlFor="department" className={"prInputLabel"}>Відділ</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="department"
                                        value={form.department || ""}
                                        onChange={changeHandler}
                                    >
                                        {fullList.Department.length > 0 &&
                                            fullList.Department.map(el => (
                                                <option value={el.id}>{el.title}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="prInput">
                                    <label htmlFor="status" className={"prInputLabel"}>Статус запиту</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="status"
                                        value={form.status || ""}
                                        onChange={changeHandler}
                                    >
                                        {fullList.StatusSupport.length > 0 &&
                                            fullList.StatusSupport.map(el => (
                                                <option value={el.id}>{el.title}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="prInputPeriod">
                                    <label htmlFor="support_date" className={"prInputLabel"}>Період створення</label>
                                    <div className="prFlexRow">
                                        <DatePicker
                                            onChange={changeHandler}
                                            id="dateFrom"
                                            value={(form.dateFrom && getDate(form.dateFrom,'dd/mm/yyyy','.')) || ''}
                                            icon={true}
                                        />
                                        <div className="prInputPeriodDevider">-</div>
                                        <DatePicker
                                            onChange={changeHandler}
                                            id="dateTo"
                                            value={(form.dateTo && getDate(form.dateTo,'dd/mm/yyyy','.')) || ''}
                                            icon={true}
                                            alignRight={true}
                                        />
                                    </div>
                                </div>
                                <div className="prInput">
                                    <label htmlFor="responsible" className={"prInputLabel"}>Відповідальний</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="responsible"
                                        value={form.responsible || ""}
                                        onChange={changeHandler}
                                    >
                                    </select>
                                </div>
                                <div className="prInput">
                                    <label htmlFor="userId" className={"prInputLabel"}>Користувач</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="userId"
                                        value={form.userId || ""}
                                        onChange={changeHandler}
                                    >
                                    </select>
                                </div>
                                <div className="userEditButtons">
                                    <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                    <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                                </div>
                                <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                            </div>
                        </div>    
                    </div>   
                </>
            )
        }
        else if (props.type === 'adminReqFilter') {
            return(
                <> 
                    <div className="overlay" onClick={closeSidebar}>
                        <div className="sidebar">
                            <h3 className="prFlexRow">
                                <div className="sidebarFilter">
                                    <Icon type='filter'></Icon> 
                                    <div>{t('filter_header')}</div>
                                </div> 
                                <div className="prFlexRow sidebarFilterButtons"> 
                                    <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                    <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                </div>
                            </h3>
                            <div className="sidebarBody">
                                <div className="prInput">
                                    <label htmlFor="id" className={"prInputLabel"}>Пошук за ID скарги</label>
                                    <input onChange={changeHandler} value={form.id || ''} id="id" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                                </div>
                                <div className="prInput">
                                    <label htmlFor="roomId" className={"prInputLabel"}>Пошук за ID кімнати</label>
                                    <input onChange={changeHandler} value={form.roomId || ''} id="roomId" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                                </div>
                                <div className="prInput">
                                    <label htmlFor="status" className={"prInputLabel"}>Статус запиту</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="status"
                                        value={props.filter.status || ""}
                                        onChange={changeHandler}
                                    >
                                        {fullList.StatusSupport.length > 0 &&
                                            fullList.StatusSupport.map(el => (
                                                <option key={el.id} value={el.id}>{el.title}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="prInputPeriod">
                                    <label htmlFor="notification_date" className={"prInputLabel"}>Період створення</label>
                                    <div className="prFlexRow">
                                        <DatePicker
                                            onChange={changeHandler}
                                            id="dateFrom"
                                            value={(form.dateFrom && getDate(form.dateFrom,'dd/mm/yyyy','.')) || ''}
                                            icon={true}
                                        />
                                        <div className="prInputPeriodDevider">-</div>
                                        <DatePicker
                                            onChange={changeHandler}
                                            id="dateTo"
                                            value={(form.dateTo && getDate(form.dateTo,'dd/mm/yyyy','.')) || ''}
                                            icon={true}
                                            alignRight={true}
                                        />
                                    </div>
                                </div>
                                <div className="prInput">
                                    <label htmlFor="responsible" className={"prInputLabel"}>Відповідальний</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="responsible"
                                        value={form.responsible || ""}
                                        onChange={changeHandler}
                                    >
                                    </select>
                                </div>
                                <div className="prInput">
                                    <label htmlFor="userId" className={"prInputLabel"}>Користувач</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="userId"
                                        value={form.userId || ""}
                                        onChange={changeHandler}
                                    >
                                    </select>
                                </div>
                                <div className="userEditButtons">
                                    <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                    <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                                </div>
                                <button className="w100 prButton prButtonSecond" onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                            </div>
                        </div>    
                    </div>   
                </>
            )
        }
        else if (props.type === 'arbiterFilter') {
            return(
                <> 
                    <div className="overlay" onClick={closeSidebar}>
                        <div className="sidebar">
                            <h3 className="prFlexRow">
                                <div className="sidebarFilter">
                                    <Icon type='filter'></Icon> 
                                    <div>{t('filter_header')}</div>
                                </div> 
                                <div className="prFlexRow sidebarFilterButtons"> 
                                    <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                    <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                </div>
                            </h3>
                            <div className="sidebarBody">
                                <div className="prInput">
                                    <label htmlFor="id" className={"prInputLabel"}>Пошук за ID запиту</label>
                                    <input onChange={changeHandler} value={form.id || ''} id="id" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                                </div>
                                <div className="prInput">
                                    <label htmlFor="roomId" className={"prInputLabel"}>Пошук за ID кімнати</label>
                                    <input onChange={changeHandler} value={form.roomId || ''} id="roomId" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                                </div>
                                <div className="prInput">
                                    <label htmlFor="status" className={"prInputLabel"}>Статус запиту</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="status"
                                        value={props.filter.status || ""}
                                        onChange={changeHandler}
                                    >
                                        {fullList.UserRoles.length > 0 &&
                                            fullList.StatusSupport.map(el => (
                                                <option key={el.id} value={el.id}>{el.title}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="prInputPeriod">
                                    <label htmlFor="birthday_period" className={"prInputLabel"}>Період створення</label>
                                    <div className="prFlexRow">
                                        <DatePicker
                                            onChange={changeHandler}
                                            id="dateFrom"
                                            value={(props.filter.dataBirthdayFrom && getDate(props.filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''}
                                            icon={true}
                                        />
                                        <div className="prInputPeriodDevider">-</div>
                                        <DatePicker
                                            onChange={changeHandler}
                                            id="dateTo"
                                            value={(props.filter.dataBirthdayTo && getDate(props.filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''}
                                            icon={true}
                                            alignRight={true}
                                        />
                                    </div>
                                </div>
                                <div className="prInput">
                                    <label htmlFor="responsible" className={"prInputLabel"}>Відповідальний</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="responsible"
                                        value={props.filter.responsible || ""}
                                        onChange={changeHandler}
                                    >
                                    </select>
                                </div>
                                <div className="prInput">
                                    <label htmlFor="game" className={"prInputLabel"}>Гра</label>
                                    <select
                                        className={ "prInputInput"}
                                        id="game"
                                        value={props.filter.game || ""}
                                        onChange={changeHandler}
                                    >
                                    </select>
                                </div>
                                <div className="userEditButtons">
                                    <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                    <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                                </div>
                                <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                            </div>
                        </div>    
                    </div>   
                </>
            )
        }
    }

    export default Filter;