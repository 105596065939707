import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../components/Icon";
import { getGameItem } from "../../redux/actions/game";
import AboutGameItemPage from "./AboutGameItemPage/AboutGameItemPage";
import RoomsGameItemPage from "./RoomsGameItemPage/RoomsGameItemPage";

const GamesItemPage =()=>{
    const { t } = useTranslation();
    const currentId = useParams().id;
    const type = useParams().type;
    const navigate = useNavigate();
    const [ready, setReady] = useState(false);
    const game= useSelector(state=>state.game?.game);
    const dispatch= useDispatch();


    const tabClick=(e)=>{
        navigate('/gameItem/'+currentId+'/'+e.target.id);
    }

    const initFunc=()=>{
        dispatch(getGameItem({id:currentId})).then(res=>{
            setReady(true)
        })
    }

    


    useEffect(() => {
        if(!ready){
            initFunc()
        }
       
    }, [ready]);

    return(<>
    {ready&&
    <>
    <div
        className="profileMainBlock prFlexRow gameItemCard"
        style={{
            backgroundImage:`url(${game.imageGame})`,
            marginBottom: '36px',
            justifyContent: 'end',
            minHeight: '432px',
        }}
    >
            {/* <div className="photoUploadCover"><Icon type='photoUpload'></Icon>
            </div> */}
            <div className="prFlexRow" style={{ width: '100%' }}>
                <div className="prFlexRow gameInfo" >  
                    <div className="gameInfoIcon" style={{backgroundImage:`url(${game.iconGame})`}}>
                    </div>
                    <span>{game.name}</span>
                    <div className="gameInfoPlatforms prFlexRow">
                        {game?.platforms?.map(elem=>{
                                        return(
                                            <Fragment key={elem}>
                                            {elem=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                                <div style={{paddingTop:'3px'}}><Icon type='pc'></Icon></div>
                                            }
                                            {elem=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                                <div style={{paddingTop:'3px'}}><Icon type='ps'></Icon></div>
                                            }
                                            {elem=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                                <div style={{paddingTop:'3px'}}><Icon type='xbox'></Icon></div>
                                            }
                                            </Fragment>
                                        )
                        })}
                    </div>
                    <div className="gameItemPlatformsMobile">
                        {game.name}
                        <div className="gameInfoPlatforms prFlexRow">
                            {game?.platforms?.map(elem=>{
                                            return(
                                                <Fragment key={elem}>
                                                {elem=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                                    <div style={{paddingTop:'3px'}}><Icon type='pc'></Icon></div>
                                                }
                                                {elem=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                                    <div style={{paddingTop:'3px'}}><Icon type='ps'></Icon></div>
                                                }
                                                {elem=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                                    <div style={{paddingTop:'3px'}}><Icon type='xbox'></Icon></div>
                                                }
                                                </Fragment>
                                            )
                            })}
                        </div>
                    </div>
                </div>
                <div className="profileTabs prFlexRow">
                    {/* <div onClick={tabClick} id='myGames' className={!type || type=='myGames'? "profileTabsItems active ":"profileTabsItems"}>{t('my_games')}</div> */}
                    <div onClick={tabClick} id='rooms' className={type=='rooms' ?"profileTabsItems active":"profileTabsItems"}>{t('rooms')}</div>
                    <div onClick={tabClick} id='about' className={!type ||type=='about' ?"profileTabsItems active":"profileTabsItems"}>{t('about_game')}</div>
                </div>
            </div>
    </div>
    {   (!type || type=='about' )&& 
        <AboutGameItemPage game={game}></AboutGameItemPage>
    }
    {
        type && type==='rooms' &&
        <RoomsGameItemPage game={game}></RoomsGameItemPage>
    }
    </>
    }
    </>)


}

export default GamesItemPage;