import {
  GETSYSTEMSETTINGS_SUCCESS,
  GETSYSTEMSETTINGS_FAIL,
  UPDATESYSTEMSETTINGS_SUCCESS,
  UPDATESYSTEMSETTINGS_FAIL,
  ERROR,
  SET_MESSAGE,
} from "./types";

import SystemService from '../services/system.service';

export const getSystemSettings = () => (dispatch) => {
  return SystemService.getSystemSettings().then( response => {
    if(response.statusText!="OK" || response.data.errors){
      dispatch({
        type: GETSYSTEMSETTINGS_FAIL,
        payload: response.data,
      });
      return response.data;
    } else {
      dispatch({
        type: GETSYSTEMSETTINGS_SUCCESS,
        payload: response.data,
      });
      return  response.data;
    }
  },
  (error) => {
    dispatch({
      type: GETSYSTEMSETTINGS_FAIL,
    });
    dispatch({
      type: ERROR,
      message:error.message
    });

    console.log(error);
    return Promise.reject();
  })
}

export const updateSystemSettings = (form, url) => (dispatch) => {
  return SystemService.updateSystemSettings(form, url).then( res => {
    if (res.statusText !== 'OK' || res.data.errors) {
      dispatch({
        type: UPDATESYSTEMSETTINGS_FAIL,
        payload: res.data,
      })

      return res.data;
    } else {
      dispatch({
        type: UPDATESYSTEMSETTINGS_SUCCESS,
        payload: res.data,
      })

      
      dispatch({
        type: SET_MESSAGE,
        message: "Дані оновлено успішно",
        messageType: 'success',
      })

      return res.data;
    }
  },
  (error) => {
    dispatch({
      type: UPDATESYSTEMSETTINGS_FAIL
    })
    dispatch({
      type: ERROR,
      message: error.message
    })

    console.log(error);
    return Promise.reject();
  })
}