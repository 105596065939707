import React from "react";

const Tabs = ({
  tabs = [],
  onChangeTab = () => {},
  activeTab = '1',
}) => {
  return (
    <ul className="tabsContainer">
      {tabs.length && tabs.map(tab => (
        <li
          key={tab.id}
          className={`tabItem ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => onChangeTab(tab.id)}
        >
          {tab.name}
        </li>
      ))}
    </ul>
  )
}

export default Tabs;