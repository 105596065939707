import { combineReducers } from "redux";
import auth from "./auth";
import room from "./room";
import user from "./user";
import support from "./support";
import transaction from "./transaction";
import currentUser from "./currentUser";
import currentRoom from "./currentRoom";
import fullList from './fullList';
import list from './list';
import error from './error';
import game from './game';
import log from './log';
import hints from './hints';
import system from "./system";
import blacklist from "./blacklist";

export default combineReducers({
  auth,
  currentUser,
  room,
  user,
  game,
  currentRoom,
  support,
  transaction,
  fullList,
  list,
  error,
  hints,
  log,
  system,
  blacklist,
});
