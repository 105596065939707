import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../components/Icon";
import Pagination from "../../components/Pagination/Pagination";
import { getGames } from "../../redux/actions/game";
import Hint from "../../components/Hint/Hint";


const GamesPage=()=>{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState({});
    const [ready, setReady] = useState(false);
    const [pageCur, setPageCur] = useState(1);
    const game = useSelector((state) => state.game);
    const hint = useSelector((state) => state.hints);
    
    const [filter, setFilter] = useState(() => {
        // Initialize filter from localStorage if available
        const savedFilter = localStorage.getItem("gamesPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });
    
    const createClick = () => {
        navigate(`/gameEdit/1`);
    };
    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
    };

    const initFunc =(page)=>{
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.sort={};
        dispatch(getGames(data)).then(res=>{
            setReady(true);
        })
    }

    useEffect(() => {
        if(!ready){
            initFunc();
        }
    }, [ready]);

    return(<>
        <div className="profileSection gamesPage" style={{marginBottom:'0px'}}>
            <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
                <h2 className="prFlexRow">{t('games')}
                    <div className="infoHelp"> <Hint align='left' item={hint[32]}/></div>
                </h2>
            </div>
            <div className="prFlexRow gameSection">
                <div className="prInput prInputIcon">
                    <label
                    htmlFor="search"
                    className={"prInputLabel"}
                    >
                    {t("search_game")}
                    </label>
                    <input
                    onChange={changeHandler}
                    id="search"
                    type={"text"}
                    className={"prInputInput"}
                    placeholder={t("search_game")}
                    />
                    <div
                    className="prInputIconIcon"
                    >
                    <Icon type={"search"} />
                    </div>
                </div>
                <button className="prButton prButtonMain" onClick={createClick}>
                        {t("create_game")}
                </button>
            </div>

            {game.items?.length>0 ? game.items.map(el=>{
                return(<>
                <div>
                    <div className="directoryElememt prFlexRow">
                        <div className="directoryElementText">
                        {el.name}
                        </div>
                        <div className="prFlexRow directoryElementButton">
                            <div className="prIconButton"   onClick={()=>{navigate('/gameEdit/'+el.id)}}>
                                <Icon type='edit'></Icon>
                            </div>
                            {/* <div className="prIconButton" onClick={()=>{}}>
                                <Icon type='close'></Icon>
                            </div> */}
                        </div>
                    </div>
                </div>
                </>)
            }):
            <div className="directoryElememt noElem">{t('no_game_found')}</div>
            }
            {game.total>game.limit&& <Pagination
                            className="pagination-bar"
                            currentPage={pageCur}
                            totalCount={game.total}
                            pageSize={game.limit}
                            onPageChange={(page) => {
                                setPageCur(page);
                                initFunc(page);
                            }}
                        />}
        </div>
    </>)
}

export default GamesPage;