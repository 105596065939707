import React, { useEffect, useRef, useState } from "react";

import { Icon } from "../Icon";

const classNames = {
  align: {
    right: 'alignRight',
    center:'alignCenter'
  }
}

const Hint = ({
  align = '',
  item={},
}) => {
  const [open, setOpen] = useState(false)

  const inputRef = useRef(null);
  const onHintClick = () => {
    setOpen( prev => !prev);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (inputRef.current && 
            !inputRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };

}, [inputRef]);

  return (
    <div
      className="hintWrapper pointer"
      onClick={onHintClick}
      ref={inputRef}
    >
      <Icon type="info"/>
      {open && (
        <>
          <div className={`hintContainer ${classNames.align[align] || ''}`}>
            <div className={`hintContainerArrow ${classNames.align[align] || ''}`} />
            <div className="hintContainerBody">
              <h5 className="hintHeading">
                {item.title}
              </h5>
              <div className="hintBody"
                dangerouslySetInnerHTML={{__html: item.description}}
              >
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Hint;