import { GETALLLOGS_SUCCESS, GETALLLOGS_FAIL } from "../actions/types";

const initialState = {}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETALLLOGS_FAIL:
      return {
        ...state
      };
    case GETALLLOGS_SUCCESS:
      return {
        list:[...payload.all],
        total:payload.total,
        limit:payload.limit,
        offset:payload.offset
      };
      default:
        return state;
  }
}