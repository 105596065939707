import React, { useState } from "react";

import { Icon } from "../Icon";

const MultiSelect = ({
  options = [],
  value = [],
  onChange = () => {},
  label = 'Default label',
  placeholder = 'Оберіть опції...',
  id = 'multiselect',
  multi = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSelection = (e, selectedValue) => {
    e.stopPropagation();
    const newValue = value.includes(selectedValue)
      ? value.filter((item) => item !== selectedValue)
      : [...value, selectedValue];


    console.log('newValue', newValue);

    onChange({id, value: newValue});
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  }

  return (
    <div className="prInput" tabIndex="0">
      <label htmlFor="multiselect" className="prInputLabel">
        {label}
      </label>
      <div className={`multiselect ${isOpen && 'focused'}`} onClick={toggleDropdown}>
        <div className="prInputInput">
          {multi && value.length === 0 ? (
            placeholder
          ) : (
            <div className="multiselectValues">
              {
                value.map( value => (
                  <div
                    key={value.value}
                    className="multiselectChip"
                  >
                    <span className="multiselectChipLabel">
                      {value.title + 'x' + value.title}
                    </span>
                    <Icon
                      type="close"
                      width="12px"
                      onClick={(e) => toggleSelection(e, value)}
                    />
                  </div>
                ))
              }
            </div>
          )}
          <Icon type="selectArrow" />
        </div>
        {isOpen && (
          <ul className="multiselectOptions">
            {
              options.length > 0 && options.map(option => (
                <li
                  key={option.id}
                  className="multiselectOption"
                  onClick={(e) => toggleSelection(e, option)}
                >
                  {option.title + 'x' + option.title}
                </li>
              ))
            }
          </ul>
        )}
      </div>
    </div>
  )
}

export default MultiSelect;