import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../components/Icon";
import checkRole from "../../helpers/checkRole";
import AdminProfileSettingsPage from "./AdminProfileSettingsPage/AdminProfileSettingsPage";
import AdminProfileWalletPage from "./AdminProfileWalletPage/AdminProfileWalletPage";

import ProfileGamesPage from './ProfileGamesPage/ProfileGamesPage';
import ProfileRoomsPage from './ProfileRoomsPage/ProfileRoomsPage';
import ProfileSettingsPage from "./ProfileSettingsPage/ProfileSettingsPage";
import ProfileWalletPage from "./ProfileWalletPage.jsx/ProfileWalletPage";

const VerificationBadge = (props) => {
    const { t } = useTranslation();

    const computeVerificationValues = () => {
        switch(props.isStatus) {
            case 0:
                return {
                    classNames: 'reputationNumber notVerified prFlexRow',
                    text: 'status_not',
                };
            case 1: 
                return {
                    classNames: 'reputationNumber pendingVerification prFlexRow',
                    text: 'status_waiting',
                };
            case 2: 
                return {
                    classNames: 'reputationNumber verified prFlexRow',
                    text: 'status_verificated',
                };
            default:
                console.log('Not found status');
        }
    };

    const { classNames, text } = computeVerificationValues();

    return (
        <div className="profileStatus">
            <div className='prFlexRow'>
                <div className={`${classNames}`}> <Icon type='passport'/> <div>{t(`${text}`)}</div> </div>
            </div>
        </div>
    )
}

const ProfilePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const type = useParams().type;
    const id = useParams().id;
    const userCur = useSelector((state) => state.currentUser.user);
    const user = useSelector((state) => state.user.currentUser?.user);
    const state = useSelector(state => state);

    const tabClick=(e)=>{
        if(!id){
            navigate('/profile/'+e.target.id);
        }else{
            navigate('/profile/'+e.target.id+'/'+id);
        }
    }

    console.log('id');
    console.log(id);
    console.log('type');
    console.log(type);

    
    return (<>
        <div className="profileMainBlock prFlexRow">
            <div className="photoUploadCover"><Icon type='photoUpload'></Icon>
            </div>
            <div className="profileMainBlockInfo prFlexRow">  
                <div className="headerUserAvatarBlock">
                    <div className='headerUserAvatar'>
                        <Icon type='avatar'></Icon>
                    </div>
                    <div className="headerUserAvatarUpload">
                        <Icon type='photoUpload'/>
                    </div>
                </div>
                <div className="profileMainBlockInfoRight">
                    <div className="prFlexRow profileMainBlockInfoRightText">
                        <div className='prFlexRow'>
                            <div  className={
                                        ((user?.reputation > 6 || userCur?.reputation > 6) && "reputationNumber") ||
                                        (((user?.reputation < 7 && user?.reputation > 3)|| (userCur?.reputation < 7 && userCur?.reputation > 3) ) && "reputationNumber caution") ||
                                        ((user?.reputation < 4||userCur?.reputation < 4 ) && "reputationNumber error")
                                    }>{user?.reputation ||userCur?.reputation }</div>
                        </div>
                        <div className="profileFio"> {((id && user?.lastName) ||(!id&& userCur?.lastName)) ? (id && user?.lastName) || (!id && userCur?.lastName):t('last_name')} {((id && user?.firstName)|| (!id &&userCur?.firstName))? ( id && user?.firstName)|| ( !id && userCur?.firstName): t('first_name')}</div>
                        <div style={{paddingTop:'3px'}} className='profileFioEditName'><Icon type='editName'></Icon></div>
                    </div>
                    <div className="profileMainBlockInfoRightNick">
                        @{(id&& user?.nickname )|| (!id && userCur?.nickname)}
                    </div>
                    { user && userCur && <VerificationBadge isStatus={id ? user.isStatus : userCur.isStatus} /> }
                    <div className="prFlexRow statistic">
                        <div className="prFlexRow white"><Icon type='playController'/><div className="statisticValue">&nbsp;: {(id && user?.generalStatistics?.total) || (!id && userCur?.generalStatistics?.total) || '0' }</div></div>
                        <div className="prFlexRow green"><Icon type='win'/><div className="statisticValue">&nbsp;:  {(id &&user?.generalStatistics?.win) || (!id &&userCur?.generalStatistics?.win) || '0'}</div></div>
                        <div className="prFlexRow red"><Icon type='loose'/><div className="statisticValue">&nbsp;:  {(id &&user?.generalStatistics?.loss) || (!id &&userCur?.generalStatistics?.loss) || '0'}</div></div>
                        <div className="prFlexRow orange"><Icon type='draft'/><div className="statisticValue">&nbsp;:  {(id &&user?.generalStatistics?.draw) || (!id &&userCur?.generalStatistics?.draw) || '0'}</div></div>
                    </div>
                </div>

            </div>
            <div className="profileTabs prFlexRow" style={{ justifyContent: 'flex-start' }}>
                {(user &&checkRole(user,'isVerified') || userCur &&checkRole(userCur,'isVerified')) && <div onClick={tabClick} id='myGames' className={(user &&checkRole(user,'isVerified') || userCur &&checkRole(userCur,'isVerified')) && (!type || type=='myGames') ? "profileTabsItems active ":"profileTabsItems"}>{t('my_games')}</div>}
                {(user &&checkRole(user,'isVerified') || userCur &&checkRole(userCur,'isVerified'))  && <div onClick={tabClick} id='rooms' className={type=='rooms' ?"profileTabsItems active":"profileTabsItems"}>{t('rooms')}</div>}
                <div onClick={tabClick} id='wallet' className={((!type && id)|| type==='wallet')?"profileTabsItems active":"profileTabsItems"}>{t('wallet')}</div>
                <div onClick={tabClick} id='settings' className={type=='settings' ?"profileTabsItems active":"profileTabsItems"}>{t('settings')}</div>
            </div>
        </div>

        {(user &&checkRole(user,'isVerified') || userCur &&checkRole(userCur,'isVerified'))  && (!type || type=='myGames') && 
            <ProfileGamesPage></ProfileGamesPage>
        }
        {
            type && type==='rooms' &&
            <ProfileRoomsPage></ProfileRoomsPage>
        }
        {((!id && type && type==='wallet')||
           ( !type && id)) &&
            <ProfileWalletPage/>
        }
        {
            type && type==='settings' && !id &&
            <ProfileSettingsPage isVerified={(user &&checkRole(user,'isVerified') || userCur &&checkRole(userCur,'isVerified'))}/>
        }
        {id && (!type || type==='wallet') && userCur&& checkRole(userCur,'admin') && 
            <AdminProfileWalletPage/>
        }
        {id && type && type==='settings' && userCur && checkRole(userCur,'admin') && 
            <AdminProfileSettingsPage/>
        }
        </>
    );
};

export default ProfilePage;