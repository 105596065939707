import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Filter from "../../../components/Filter/Filter";
import Hint from "../../../components/Hint/Hint";
import { Icon } from "../../../components/Icon";
import Pagination from "../../../components/Pagination/Pagination";
import { addNickname, getGames, getMyGames } from "../../../redux/actions/game";
import { getCurrentUserDetail } from "../../../redux/actions/user";


const ProfileGamesPage=()=>{
    const { t } = useTranslation();
    const user = useSelector((state) => state.currentUser.user);
    const hint = useSelector((state) => state.hints);
    const [ready,setReady] =useState(false);
    const [formMy,setFormMy] =useState({});
    const navigate=useNavigate();
    const [formAll,setFormAll] =useState({});
    const [pageCur, setPageCur] = useState(1);
    const [filterModal, setFilterModal] = useState(false);
    const [form, setForm] = useState({});
    const [readyAll,setReadyAll] =useState(false);
    const addMeToGame=(elem)=>{
        let data = {...elem};
        if(data.platform?.length>0){

        }else{
            data.platform=[];
        }
        setForm(data);
        setFilterModal(true);
    }
    const selectClick=(e)=>{
        e.preventDefault();
        let arr = form.platform;
        let el = e.target.id;
        if (arr.indexOf(el) !== -1) {
            arr = arr.filter(item => item !== el);
        } else {
            arr.push(el);
        }
        setForm({
            ...form,
            platform: arr
        });
    }

    const saveFunc =()=>{
        let data={};
        data.id=form.id;
        data.platform=form.platformNew;
        dispatch(addNickname(data)).then(res=>{
            setFilterModal(false);
            dispatch(getCurrentUserDetail({id:user.id}));
            navigate('/gameItem/'+data.id+'/rooms');
        })
    }

    const changeHandler =(e)=>{
        const { id, value } = e.target;
        let formChange = { ...form };
            if(formChange.platformNew && formChange.platformNew[id]){
                formChange.platformNew[id] = value;
            }else if(formChange.platformNew){
                formChange.platformNew[id] = value;
            }
            else{
                formChange.platformNew={};
                formChange.platformNew[id] = value;
            }
        setForm(formChange);
    }
    const dispatch=useDispatch();

    const getAllGames=()=>{
        dispatch(getGames({})).then(res=>{
            setFormAll(res);
            setReadyAll(true)
        })
    }
    const getMyGameInit=()=>{
        dispatch(getMyGames()).then(res=>{
            setFormMy(res);
            setReady(true)
        })
    }
    useEffect(() => {
        if(!readyAll){
            getAllGames()
        }
        
    }, [readyAll]);

    useEffect(() => {
        if(!ready){
            getMyGameInit()
        }
    }, [ready]);

    return(<>
    <div className="profileSection">
            <h2>{t('my_games')}
                <div className="infoHelp">
                    <Hint align='left' item={hint[0]}/>
                </div>
            </h2>
            <div className="gameList">
                {user?.gamesNickname?.length>0 &&  user.gamesNickname.map(elem=>(<div className="gameItem" onClick={()=>navigate('/gameItem/'+elem.gamePlay.id+'/rooms')} style={{backgroundImage:`url(${elem.gamePlay.iconGame})`,cursor:'pointer'}}>
                    <div className="gameItemEdit" ><Icon type='edit'/></div>
                    <div className="gameItemBlockInfo">
                        <div className=" prFlexRow profileMainBlockInfoRightText">
                            <div className="profileFio">{elem.gamePlay.name}</div>
                                    {elem.platforms.map(el=>{
                                        
                                        return(
                                            <>
                                                {el.platform=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                                    <div style={{paddingTop:'3px'}}><Icon type='pc'></Icon></div>
                                                }
                                                {el.platform=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                                    <div style={{paddingTop:'3px'}}><Icon type='ps'></Icon></div>
                                                }
                                                {el.platform=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                                    <div style={{paddingTop:'3px'}}><Icon type='xbox'></Icon></div>
                                                }
                                            </>
                                        )
                                    })}
                        </div>
                        <div className="profileMainBlockInfoRightNick">
                            {/* {t('game_nick')}: <span style={{fontWeight:'700'}}>{elem.gamesNickname.nickname}</span> */}
                        </div>
                        <div className="prFlexRow statistic">
                            <div className="prFlexRow white"><Icon type='playController'/><div className="statisticValue">&nbsp;: {elem?.result?.total}</div></div>
                            <div className="prFlexRow green"><Icon type='win'/><div className="statisticValue">&nbsp;: {elem?.result?.win}</div></div>
                            <div className="prFlexRow red"><Icon type='loose'/><div className="statisticValue">&nbsp;: {elem?.result?.loss}</div></div>
                            <div className="prFlexRow orange"><Icon type='draft'/><div className="statisticValue">&nbsp;: {elem?.result?.draw}</div></div>
                        </div>
                    </div>
                </div>))}

            </div>
            {user?.gamesNickname?.length>8&&<Pagination
                className="pagination-bar"
                currentPage={pageCur}
                totalCount={30}
                pageSize={8}
                onPageChange={async (page) => {
                }}
            />}

        </div>

        <div className="profileSection" style={{marginBottom:'0px'}}>
            <div className="prFlexRow profileSectionHeader">
                <h2 >{t('popular_games')}</h2>
                <a className="profileSectionHeaderLink link" onClick={()=>navigate('/gamesLibrary')}>{t('all_games')}<Icon type='rightarrow'/></a>
            </div>
           
            <div className="gameList">
                {formAll?.items?.map(el=>{
            return(<>
            <div className="gameItem gameItemPopular" onClick={(e)=>{e.preventDefault();e.stopPropagation();navigate('gameItem/'+el.id+'/rooms')}} style={{backgroundImage:`url(${el.iconGame})`,cursor:'pointer'}}>
                    <div className="gameItemEdit"><Icon type='bigplus'  onClick={(e)=>{e.preventDefault();e.stopPropagation();addMeToGame(el)}}/></div>
                    <div className="gameItemBlockInfo">
                        <div className=" prFlexRow profileMainBlockInfoRightText">
                            <div className="profileFio">{el.name}</div>
                                {el?.platforms?.map(elem=>{
                                return(
                                    <>
                                    {elem=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                        <div style={{paddingTop:'3px'}}><Icon type='pc'></Icon></div>
                                    }
                                    {elem=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                        <div style={{paddingTop:'3px'}}><Icon type='ps'></Icon></div>
                                    }
                                    {elem=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                        <div style={{paddingTop:'3px'}}><Icon type='xbox'></Icon></div>
                                    }
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </>)
        })
        }

            </div>

        </div>
        {filterModal && <Filter type='addMyGame' accept={saveFunc} filter={form} setFilter={setForm} onChange={changeHandler} selectClick={selectClick} isOpen={filterModal} setIsOpen={setFilterModal} ></Filter>}

    </>)

}

export default ProfileGamesPage;