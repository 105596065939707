import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTransactionsList } from "../../redux/actions/transaction";
import { useNavigate } from "react-router-dom";
import getDate from "../../helpers/getDate";

import Pagination from "../../components/Pagination/Pagination";
import Filter from "../../components/Filter/Filter";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Icon } from "../../components/Icon";
import Hint from "../../components/Hint/Hint";

const sortOptions = [
    { id: "createdAt", value: "За датою створення" },
];

const TransactionListPage=()=>{
    const { t } = useTranslation(); 
    const [ready, setReady] = useState(false);
    const [list, setList] = useState({});
    const [pageCur, setPageCur] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState({});
    const [needGet, setNeedGet] = useState(false);
    const [sort,setSort]=useState('asc');
    const [sortValue,setSortValue]=useState('createdAt');
    const stateList = useSelector((state) => state.list);
    const hint = useSelector((state) => state.hints);
    
    const navigate = useNavigate();
    
    const dispatch=useDispatch();

    const initFunc=(page=1)=>{
        let data = {};
        data.limit = 10;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.addPath = '/admin';
        data.sort={};
        data.sort[sortValue]=sort;

        dispatch(getTransactionsList(data)).then(res=>{
          setList({...res})
          setReady(true);
        })
    }
     useEffect(() => {
        if(!ready){
            initFunc();
        }
            
    }, [ready]);

    const useFilter =()=>{
        initFunc(1)
    }

    const resetFilter=()=>{
        setFilter({});
        initFunc(1);
    }

    const goToProfile = (id) => {
        navigate(`/profile/wallet/${id}`)
    }

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else 
        if (id === "dateFrom" || id === "dateTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
            setNeedGet(true);
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setFilter(updatedFilter);
    };

    return(
        <div className="profileSection usersPage" style={{ marginBottom: "0px" }}>
            <div className="prFlexRow profileSectionHeader">
                <h2>
                    Транзакції
                    <div className="infoHelp"> <Hint align='left' item={hint[35]}/></div>

                </h2>
                <button className="prButton prButtonSecond prFlexRow roomFilterButton roomFilterMobileButton" onClick={() => setIsOpen(true)}>
                    <Icon type="filter" /> {t("filter_show")}
                </button>
            </div>

            <div className="filterBlock" style={{ marginBottom: '36px' }}>
                <div className="prFlexRow">
                    <div className="prFlexRow">
                        <div className="prFlexRow filterBlockFilter">
                            <div className="filterBlockSortText">{t("filter_period")}:</div>
                            <DatePicker id="dataBirthdayFrom" value={(filter.dataBirthdayFrom?.length>1 && getDate(filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} onBlur={initFunc} icon={true} />
                            <div>-</div>
                            <DatePicker id="dataBirthdayTo" value={(filter.dataBirthdayTo?.length>1 && getDate(filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''} onChange={changeHandler}  onBlur={initFunc} icon={true} />
                        </div>
                        <div className="filterBlockSort prFlexRow">
                            <div className="filterBlockSortText">{t("filter_sort")}:</div>
                            <div className="filterBlockSortValue prFlexRow" onClick={()=>setSort(sort=='asc'?'desc':'asc')}>
                                <div className={sort=='asc'?'norm':'pervert'}><Icon type="filterArrow" /></div>
                                <div>{sortOptions[0].value}</div>
                                <Icon type="selectArrow" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="prButton prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                            <Icon type="filter" /> {t("filter_show")}
                        </button>
                    </div>
                </div>
                <div className="filterBlockFilteredText">
                    {t("filter_find")} {list.totalFilter} {t("filter_all")} {list.total}
                </div>
            </div>

            {ready && (
                <>
                    {list?.transactions?.length>0 && list.transactions.map(el=>{
                        return(
                            <div className="roomItem prFlexRow userTransactionsBlock">
                                <div className="roomItemTabletBlockHeader prFlexRow">
                                    <div className="roomItemLink pointer" onClick={() => goToProfile(el.userId)}>{el.id}</div>
                                    <div className="prFlexRow">
                                        <div className="roomItemBlockValue"><Icon type='time'></Icon>   {getDate(el.createdAt.replace('T',' '),'hh:mm',":")}</div>
                                        <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                                    </div>
                                </div>
                                <div className="roomItemMobileBlockHeader">
                                    <div>
                                        <div className="roomItemBlockHeader">
                                            ID транзакції
                                        </div>
                                        <div className="roomItemLink pointer" onClick={() => goToProfile(el.userId)}>{el.id}</div>
                                    </div>
                                    <div className="roomItemMoney">
                                        <div className="roomItemMoneyValue prFlexRow">{el.quantity} <Icon type='fTocken'></Icon></div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="roomItemBlockHeader">{t('transaction_id')}</div>
                                    <div className="roomItemLink croppedText pointer" onClick={() => goToProfile(el.userId)}>{el.id}</div>
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">Нік користувача</div>
                                    <div className="roomItemBlockValue">{el.transactionUser.nickname}</div>
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('transaction_type')}</div>
                                    <div className="roomItemBlockValue">{el.type=='0'?"Виведення":"Поповнення"}</div>
                                </div>
                                <div className="roomItemMoney roomItemFullBlock">
                                    <div className="roomItemMoneyValue prFlexRow">{el.quantity} <Icon type='fTocken'></Icon></div>
                                </div>
                                <div className="roomItemMobileBlock">
                                    <div className="roomItemBlockHeader">{t('status')}</div>
                                    <div className="roomItemBlockValue">{stateList[el.transactionStatus]}</div>
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('payment_org')}</div>
                                    <div className="roomItemBlockValue">{el.paymentSystem}</div>
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">{t('time_created')}</div>
                                    <div className="prFlexRow"> 
                                            <div className="roomItemBlockValue"><Icon type='time'></Icon>   {el.createdAt?getDate(el.createdAt.replace('T',' '),'hh:mm',":"):'--:--'}</div>
                                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {el.createdAt?getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',"."):'--.--.----'}</div>
                                        </div>
                                </div>
                            </div>
                        )}
                    )}

                    {list.total > list.limit && <Pagination
                        className="pagination-bar"
                        currentPage={pageCur}
                        totalCount={list.total}
                        pageSize={list.limit}
                        onPageChange={(page) => {
                            setPageCur(page);
                            initFunc(page);
                        }}
                    />}

                    {isOpen && <Filter type='transaction' resetFilter={resetFilter} accept={useFilter} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
                </>
            )}
        </div>
    )

}

export default TransactionListPage;